import React, {useRef, useEffect} from 'react';
import "./App.scss";
import Header from "./components/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

import Asics from "./pages/asics";
import Adidas from "./pages/adidas";
import Dolby from "./pages/dolby";
import Jordan from "./pages/jordan";
import Toyota from "./pages/toyota";
import Toyotacom from "./pages/toyotacom";
import Mfa from "./pages/mfa";
import Leviscom from "./pages/leviscom";
import Lvc from "./pages/lvc";
import Levis from "./pages/levis";
import Jordancom from "./pages/jordancom";
import Converse from "./pages/converse";
import Conversespark from "./pages/conversespark";
import Geancient from "./pages/geancient";
import Vans from "./pages/vans";
// import Playground from ".pages/playground"
import ScrollToTop from "./components/ScrollToTop";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { gsap, Power2, Power3 } from "gsap";



// import {
//   CSSTransition,
//   TransitionGroup,
// } from 'react-transition-group';

//smoothscroll is for smooth scrolling
// import SmoothScroll from "./components/SmoothScroll" 

function App() {
  return (
    <Router>
       {/* <SmoothScroll> */}
       <ScrollToTop />
      <div className='App'><section id='topOfPage'></section>
        <Header /> 
        <div className='container'>
          <div className='wrapper'>
            <div className='home'>
              <Route render={({location})=> ( 
              //  <TransitionGroup>
              //  <CSSTransition
              //    key= {location.key}
              //    timeout={450}
              //    classNames="fade"
              //    >
              <Switch location={location}>
                <Route exact path='/' component={Home} />

                
                <Route exact path='/asics'><Asics /></Route>
                <Route exact path='/dolby'><Dolby /></Route>
                <Route exact path='/jordan'><Jordan /></Route>                
                <Route exact path='/adidas'><Adidas /></Route> 
                <Route exact path='/toyota'><Toyota /></Route>
                <Route exact path='/toyotacom'><Toyotacom /></Route>
                <Route exact path='/mfa'><Mfa /></Route>            
                <Route exact path='/levis'><Levis /></Route>
                <Route exact path='/lvc'><Lvc /></Route>                
                <Route exact path='/leviscom'><Leviscom /></Route>
                <Route exact path='/jordancom'><Jordancom /></Route>
                <Route exact path='/converse'><Converse /></Route>
                <Route exact path='/conversespark'><Conversespark /></Route>
                <Route exact path='/geancient'><Geancient /></Route>
                <Route exact path='/vans'><Vans /></Route>                
                {/* <Route exact path='/playground'><Playground /></Route>         */}
              </Switch>
              //  </CSSTransition>
              //  </TransitionGroup>
              )} />
            </div>
          </div>
        </div>
      </div>
      {/* </SmoothScroll> */}
    </Router>
  );
}


// function general() {
//   return <p>imagination.</p>;
// }
// function dolby() {
//   return <p>dolby.</p>;
// }
// function levis() {
//   return <p>Levis.</p>;
// }






function Home() {

  let projectlist = useRef(null);
  let awards = useRef(null);
  let contacts = useRef(null);



  useEffect (() => {

    const projectcontent = projectlist.children[0];
    const awardscontent = awards.children[0];
    const contactcontent = contacts.children[0];
    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-12', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-22', {y: '-100vh', delay: .7});
    gsap.to('.layer-32', {y: '-100vh', delay: .9});
    gsap.to('.overlay2', {y: '-200vh', delay: 1.5});

    
    gsap.from([projectcontent.children], 1, {
      scrollTrigger: {
        trigger : ".box21", 
        start : "top 90%",
        // markers: true,
        scrub: true,
        toggleActions: "play none none none",
    },
      y: 200, 
      stagger: .15,
      ease: Power2.easeOut,
      delay: 0.4,
      opacity:0,
  }, .15, )   

  // 
    gsap.from([awardscontent.children], 1, {
      scrollTrigger: {
        trigger : ".box35", 
        start : "top 60%",
        // markers: true,
        // scrub: true,
        toggleActions: "play none none none",
    },
      y:244,
      stagger: .1,
      ease: Power3.easeOut,
      delay: 0.8,
      opacity:0,

  }, .15, 'start')

  gsap.from([contactcontent.children], 1, {
    scrollTrigger: {
      trigger : ".box45", 
      start : "top 60%",
      // markers: true,
      toggleActions: "play none none none",
  },
    y:244,
    stagger: .1,
    ease: Power3.easeOut,
    delay: 0.8,
    opacity:0,

}, .15, 'start')



  })


  return (
  
   
      <div className='container'>
        
         <div className='wrapperbody'> 
          
              <div className="box1"></div>
                <div className="box2"></div>
                <div className="box3"></div>
                <div className="box4"></div>
                <div className="box5"></div>
                <div className="box6">
                    <div className="welcometext">Driving culture</div>
                    <div className="links"><AnchorLink className="cool-link" href="#work">Work</AnchorLink></div>
                    <div className="links"><AnchorLink className="cool-link" href="#awards">Awards<br /> </AnchorLink></div>
                    <div className="links"><AnchorLink className="cool-link" href="#contact">Contact<br /> </AnchorLink></div>
                </div>
                <div className="box7"></div>
                <div className="box8"></div>
                <div className="box9"></div>
                <div className="box10"></div> 
                <div className="box11"></div>
                <div className="box12"></div>
                <div className="box13"></div>
                <div className="box14"> </div>
                <div className="box15"></div> 
                <div className="box16"><div className="work" id='work'>WORK</div></div>
                <div className="box17"></div> 
                <div className="box18"></div>   
                <div className="box19"></div>
                <div className="box20"></div>   
                <div className="box21" ref={el => projectlist = el}>
                        <div className="projects">
                              <span className="Catergory">Brand Design &#10149; </span> <Link to='/dolby'>dolby</Link> <span className="Catergory"> Activation &#10149; </span> <Link to='/jordan'>Jordan The Last Shot .</Link>  <Link to='/toyota'>Toyota The Impossilbe Quest .</Link>  <span className="Catergory">Experience Design &#10149; </span>  <Link to='/jordancom'>Jordan/com . </Link> <Link to='/toyotacom'>Toyota/com . </Link> <Link to='/Mfa'>Toyota Mobility For All . </Link><Link to='/leviscom'>Levis/com . </Link> <Link to='/lvc'>Levis Vintage Clothing . </Link> <span className="Catergory">Campaign &#10149;  </span> <Link to='/asics'>Asics I Move Me . </Link> <Link to='/levis'>Live In Levis . </Link> <Link to='/adidas'>adidas Unite All Originals . </Link>  <Link to='/converse'>Converse Lyrics . </Link> <Link to='/conversespark'>Converse Spark . </Link> <Link to='/geancient'>GE Ancient Inventions . </Link>  <Link to='/vans'>Vans vs Vans &#9737;</Link> 
                        </div> 
                </div>
                <div className="box22"></div> 
                <div className="box23"></div>
                <div className="box24"></div>   
                <div className="box25"></div>   
                <div className="box26"></div>   
                <div className="box27"></div>
                <div className="box28"></div> 
                <div className="box29"></div>
                <div className="box30"></div>

                <div className="box31" ID='awards'>      </div>
                <div className="box32"></div>   
                <div className="box33"></div>
                <div className="box34"></div>   
                <div className="box35"></div>

                <div className="box36" ref={el => awards = el}>
                  <div className="list">
                  <div className="awards" >AWARDS</div>
                    <div className="awardwon">Webbys - Toyota.com</div>
                    <div className="awardwon">Cannes Gold - Start your impossible / Toyota</div>
                    <div className="awardwon">FWA SiteofDay Toyota - The Impossible Quest</div>
                    <div className="awardwon">Cannes Gold - Jordan the last shot</div>
                    <div className="awardwon">Cannes Silver – Jordan the last shot </div>
                    <div className="awardwon">Cannes Silver – Jordan the last shot </div>
                    <div className="awardwon">Cannes Bronze - Jordan the last shot </div>
                    <div className="awardwon">D&AD Wood Pencil - Jordan the last shot</div>
                    <div className="awardwon">OneShow Gold - Jordan the last shot</div>
                    <div className="awardwon">OneShow Gold - Jordan the last shot</div>
                    <div className="awardwon">OneShow Gold - Jordan the last shot</div>
                    <div className="awardwon">OneShow Silver - Jordan the last shot</div>
                    <div className="awardwon">OneShow Silver - Jordan the last shot</div>
                    <div className="awardwon">OneShow Bronze - Jordan the last shot</div>
                    <div className="awardwon">OneShow Bronze - Jordan the last shot</div>
                    <div className="awardwon">OneShow Merit x3 - Jordan the last shot</div>
                    <div className="awardwon">FWA SiteofDay- Live in Leivs</div>
                    <div className="awardwon">Cannes Merit - Dial a Home</div>
                    <div className="awardwon">Effies Silver - Adidas unite all originals</div>
                    <div className="awardwon">Webbys People’s choice – GE Ancient Inventions</div>
                    <div className="awardwon">Webbys Honoree Award – GE Ancient Inventions</div>
                    <div className="awardwon">Webbys Honoree Award - GE Paper</div>
                    <div className="awardwon">FWA SiteofDayy - GE Ancient inventions</div>
                    <div className="awardwon">Oneshow Merit – VANS Vs VANS.</div>
                    <div className="awardwon">Oneshow Merit - Lee Jeans, Life Fashion Shoot.</div>
                    <div className="awardwon">Spikes Silver - VANS Vs VANS</div>
                    <div className="awardwon">Effies Gold - HP My computer my stage</div>
                    <div className="awardwon">Effies Gold - HP My computer my stage</div>
                    <div className="awardwon">Effies Judges reco. - HP My computer my stage</div>
                    <div className="awardwon">Effies Best Campaign - HP My computer my stage</div>
                    <div className="awardwon">Effies Gold Award - Ikea Sleep Revolution</div>
                    <div className="awardwon">Asia Interactive Gold - HP hiphop </div>
                    <div className="awardwon">Asia Interactive Silver - HP hiphop </div>
                    <div className="awardwon">Asia Interative Best Animation - HP hiphop </div>
                    <div className="awardwon">Asia Interative Best use of sound - HP hiphop </div>

                    <div className="awardwon">Asia Interactive Bronze Best Mobile - Burton</div>
                    <div className="awardwon">Asia Interactive Silver Website – Prius</div>
                    <div className="awardwon">Asia Interactive Silver Viral – Google, World’s Longest URL</div>
                    <div className="awardwon">China 4A Gold Viral - Google, World’s Longest URL</div>
                    <div className="awardwon">China 4A Silver -  Prius, Tomorrow’s Promise</div>
                    <div className="awardwon">China 4A Gold -  Lexus, ES</div>
                    <div className="awardwon">New York Festivals Silver -  Lexus, ES</div>
                    <div className="awardwon">New York Festivals Finalist - Google World’s Longest URL </div>
                    <div className="awardwon">New York Festivals Silver - Cisco Bank of the future </div>
                    <div className="awardwon">Times New Media Gold - Cisco Bank of the future </div>
                    <div className="awardwon">Times New Media Silver - Cisco Bank of the future </div>

                    <div className="awardwon">DM Awards Gold - Best Craft in Execution, K-facts</div>
                    <div className="awardwon">DM Awards Gold - Best Use of Channel, K-facts </div>
                    <div className="awardwon">DM Awards Silver - K-facts </div>
                    <div className="awardwon">PMA Awards Gold - K-facts </div>
                    <div className="awardwon">PMA Awards Gold - Best Cause, Light of thanks</div>
                    <div className="awardwon">PMA Awards Silver - OCBC Financial Learning</div>
                    <div className="awardwon">PMA Awards Gold - Feedback Unit Campaign</div>
                    <div className="awardwon">CrowBar Awards Gold - Interactive student of year, Echos of Life</div>
                    
                  </div> 
                </div>  
                <div className="box37"></div> 
                <div className="box38"></div>
                <div className="box39"></div>
                <div className="box40"></div>
                <div className="box41"></div>
                <div className="box42"></div>   
                <div className="box43"></div> 
                <div className="box44"></div>   
                <div className="box45"></div>
                 <div className="box46" ref={el => contacts = el}>
                  <div className="contactlist " ID='contact'>
                    <div className="contact">CONTACT</div>
                    <div className="contactdetails inneremail"><a href="mailto:dwayne.koh@gmail.com">dwayne.koh@gmail.com</a> </div>
                    <div className="contactdetails social"><a href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">lnkdn.</a><a href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">instrgrm.</a><a href="https://www.facebook.com/dwaynekoh" target="_blank" rel="noopener noreferrer">fcbk.</a><a href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">twitt.</a></div>
                  </div>
                </div>
                <div className="box47"></div> 
                <div className="box48"></div> 
                <div className="box49"></div>   
                <div className="box50"></div>

                <div className="box51">
                  <div className="contactdetails">
                      
                      
                  </div>
                </div>
                <div className="box52"></div>   
                <div className="box53"></div>
                <div className="box54"></div>   
                <div className="box55"></div>
                {/* <div className="box56"></div>
                <div className="box57"></div>   
                <div className="box58"></div>
                <div className="box59"></div>   
                <div className="box60"></div> */}
          </div>


                <div className="footermain"> 
                          <div className="horizontalline"></div>
                          <div className="socialfeed">
                              <div className="item1">
                              </div>
                              <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>                
                          </div>
                </div>

           <div className="overlay2">
                            <div className="layer2 layer-12"></div>
                            <div className="layer2 layer-22"></div>
                            <div className="layer2 layer-32"></div>
          </div>
     

      </div>



  );
}




export default App;
