import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/conversespark/hero.jpg";
import Img1 from "../images/conversespark/01.jpg";
import Img2 from "../images/conversespark/02.jpg";
import Img3 from "../images/conversespark/03.jpg";
import Img4 from "../images/conversespark/04.jpg";
import Img5 from "../images/conversespark/05.jpeg";
import Img6 from "../images/conversespark/06.jpg";
import Img7 from "../images/conversespark/07.jpg";
// import Img8 from "../images/conversespark/08.jpg";
// import Img9 from "../images/conversespark/09.jpg";
// import Img10 from "../images/conversespark/10.png";
// import Img10 from "../images/conversespark/10.jpg";
// import Img11 from "../images/conversespark/11.jpg";
// import Img12 from "../images/conversespark/12.jpg";
// import Img13 from "../images/conversespark/13.jpg";
// import Img14 from "../images/conversespark/14.jpg";
// import Img15 from "../images/conversespark/15.jpg";
// import Img16 from "../images/conversespark/16.jpg";
// import Img17 from "../images/conversespark/17.jpg";
// import Img18 from "../images/conversespark/18.jpg";
// import Img19 from "../images/conversespark/dancegirl.gif";
// import Img20 from "../images/conversespark/danceman.gif";
// import Img21 from "../images/conversespark/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Conversespark = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let spark1 = useRef(null);
    let spark2 = useRef(null);
    let spark3 = useRef(null);
    let spark4 = useRef(null);
    let spark5 = useRef(null);
    let spark6 = useRef(null);
    let spark7 = useRef(null);
    // let spark8 = useRef(null);
    // let spark9 = useRef(null);
    // let spark10 = useRef(null);
    // let spark11 = useRef(null);
    // let spark12 = useRef(null);
    // let spark13 = useRef(null);
    // let spark14 = useRef(null);
    // let spark15 = useRef(null);
    // let spark16 = useRef(null);
    // let spark17 = useRef(null);    
    // let spark18 = useRef(null);
    // let spark19 = useRef(null);
    // let spark20 = useRef(null);
    
       

    let sparkimagereveal = CSSRulePlugin.getRule(".images:after");
    let sparkimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let sparkimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let sparkimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let sparkimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let sparkimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let sparkimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let sparkimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    // let sparkimagereveal8 = CSSRulePlugin.getRule(".eight:after");




    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    
      gsap.from(" .video2 ", {
        scrollTrigger: {
            trigger : ".video2",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(sparkimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(sparkimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(spark1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(sparkimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(spark2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(sparkimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(spark3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(sparkimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(spark4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(sparkimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(spark5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(sparkimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(spark6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(sparkimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(spark7, 1.4, {
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   







            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND CAMPAIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Converse</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                    <div className="title">AGENCY</div>
                    <div className="name">Wieden Kennedy</div>                 
                    <div className="title">Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    <div className="title">Senior Creative</div>
                    <div className="name">Julie Liu</div>
                    <div className="title">Art Director</div>                    
                    <div className="name">Morris Lee, Tea Qiu</div>
                    <div className="title">User Experience</div>
                    <div className="title">Copywriter</div>
                    <div className="name">Zebra</div>
                    <div className="title">UX</div>
                    <div className="name">Adrian Lai</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">This campaign devoted to showing creativity in an accessible way to inspire a generation of youths to step outside their comfort zones and create. We celebrate the creative process by featuring artists around Asia, who take everyday objects and turn them into interesting works of art.</div>
                {/* <p><div className="headlinebrandtext">Throughout its history it has enjoyed a strong connection with independent music. Our starting point for Converse in China was to create an authentic connection to the Chinese independent music scene. To inspire originality and empower youths. Youths that are loud, some quiet, but they are all on their own path. And they all believe they can create something better. The work we produced was to drive that empowerment. The campaigns I oversaw were launched for in the Chinese and South East Asia/Asian market.</div></p> */}
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Converse Spark </div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="video640">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/430914127"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b42"></div>   
         <div className="b43">    

        </div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota">
             <div className="first"><img ref={el => spark1 = el} width="100%" src={Img1}  /></div>
             <div className="second"><img ref={el => spark2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => spark3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => spark4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => spark5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => spark6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => spark7 = el} width="100%" src={Img7} /></div>
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51">
         </div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}
{/* 
         <div className="jordanb66"></div>
         <div className="jordanb67"></div>
         <div className="jordanb68"></div>
         <div className="jordanb69"></div>   
         <div className="jordanb70"></div> */}

         {/* <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div>  */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/converse'>Back<p>Converse</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/geancient'>Next<p>GE, AI</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Conversespark;