import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/toyotacom/hero.jpg";
import Img1 from "../images/toyotacom/01.jpg";
import Img2 from "../images/toyotacom/02.jpg";
import Img3 from "../images/toyotacom/03.jpg";
import Img4 from "../images/toyotacom/04.jpg";
import Img5 from "../images/toyotacom/05.jpg";
import Img6 from "../images/toyotacom/06.gif";
import Img7 from "../images/toyotacom/07.gif";
import Img8 from "../images/toyotacom/08.gif";
import Img9 from "../images/toyotacom/09.jpg";
import Img10 from "../images/toyotacom/10.jpg";
// import Img10 from "../images/toyota/10.jpg";
// import Img11 from "../images/toyota/11.jpg";
// import Img12 from "../images/toyota/12.jpg";
// import Img13 from "../images/toyota/13.jpg";
// import Img14 from "../images/toyota/14.jpg";
// import Img15 from "../images/toyota/15.jpg";
// import Img16 from "../images/toyota/16.jpg";
// import Img17 from "../images/toyota/17.jpg";
// import Img18 from "../images/toyota/18.jpg";
// import Img19 from "../images/toyota/dancegirl.gif";
// import Img20 from "../images/toyota/danceman.gif";
// import Img21 from "../images/toyota/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Toyotacom = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let timage1 = useRef(null);
    let timage2 = useRef(null);
    let timage3 = useRef(null);
    let timage4 = useRef(null);
    let timage5 = useRef(null);
    let timage6 = useRef(null);
    let timage7 = useRef(null);
    let timage8 = useRef(null);
    let timage9 = useRef(null);
    let timage10 = useRef(null);
    // let timage11 = useRef(null);
    // let timage12 = useRef(null);
    // let timage13 = useRef(null);
    // let timage14 = useRef(null);
    // let timage15 = useRef(null);
    // let timage16 = useRef(null);
    // let timage17 = useRef(null);    
    // let timage18 = useRef(null);
    // let timage19 = useRef(null);
    // let timage20 = useRef(null);
    
       

    let tcomimagereveal = CSSRulePlugin.getRule(".images:after");
    let tcomimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let tcomimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let tcomimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let tcomimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let tcomimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let tcomimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let tcomimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let tcomimagereveal8 = CSSRulePlugin.getRule(".eight:after");

    let tcomimagereveal9 = CSSRulePlugin.getRule(".nine:after");
    let tcomimagereveal10 = CSSRulePlugin.getRule(".ten:after");
    let tcomimagereveal11 = CSSRulePlugin.getRule(".eleven:after");
    let tcomimagereveal12 = CSSRulePlugin.getRule(".twelve:after");
    let tcomimagereveal13 = CSSRulePlugin.getRule(".thirteen:after");
    let tcomimagereveal14 = CSSRulePlugin.getRule(".fourteen:after");
    let tcomimagereveal15 = CSSRulePlugin.getRule(".fifthteen:after");
    let tcomimagereveal16 = CSSRulePlugin.getRule(".sixteen:after");
    let tcomimagereveal17 = CSSRulePlugin.getRule(".seventeen:after");
    let tcomimagereveal18 = CSSRulePlugin.getRule(".eighteen:after");
    let tcomimagereveal19 = CSSRulePlugin.getRule(".nineteen:after");
    let tcomimagereveal20 = CSSRulePlugin.getRule(".twenty:after");
    // let tcomimagereveal21 = CSSRulePlugin.getRule(".twentyone:after");    


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .space41 ", {
        scrollTrigger: {
            trigger : ".b41", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(tcomimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(tcomimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(timage1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(tcomimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(timage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(tcomimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(timage3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(tcomimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(timage4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(tcomimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(timage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(tcomimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(timage6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(tcomimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    // gsap.from(timage7, 1.4, {
                    //     scrollTrigger: {
                    //         trigger : ".seventh", 
                    //         start : "top 60%",
                    //         // markers : true,
                    //         toggleActions: "restart none none reverse",
                    //     },
                    //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(tcomimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

// gsap.from(timage8, 1.4, {
//     scrollTrigger: {
//         trigger : ".eight", 
//         start : "top 60%",
//         // markers : true,
//         toggleActions: "restart none none reverse",
//     },
//     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


    gsap.to(tcomimagereveal9, 1.4, { 
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
            ease: Power2.easeInOut, 
    })            
    
    gsap.from(timage9, 1.4, {
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
        
        gsap.to(tcomimagereveal10, 1.4, { 
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 60%",
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
                ease: Power2.easeInOut, 
        })            
        
        // gsap.from(timage10, 1.4, {
        //     scrollTrigger: {
        //         trigger : ".ten", 
        //         start : "top 60%",
        //         // markers : true,
        //         toggleActions: "restart none none reverse",
        //     },
        //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  





            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">EXPERIENCE DESIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Toyota Solutions</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
             <div className="title">AGENCY</div>
                    <div className="name">Saatchi LA</div>                   
                    <div className="title">Executive Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Creative Director</div>
                    <div className="name">Renato, Andy Hekimian</div>
                    
                    <div className="title">Copywriter</div>
                    <div className="name">Corey Rosenberg, John Naderi</div>
                    <div className="title">Art Director</div>
                    <div className="name">Logan Miller</div>
                    <div className="title">User Experience</div>
                    <div className="name">Nick Imondi</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">Leading all things digital by providing thought leadership across the digital landscapes and producing best in class solutions is my role on Toyota.With 8 million unique hits a month, Toyota.com is the pride of the company's digital properties and consumers primary digital destination when it comes to shopping for cars. My goal is to reimagine this platform for the future, and break category conventions from what a car buying experience is today</div>
               <p><div className="headlinebrandtext">In the day to day, I lead and manage a team of 30 Creatives- Senior creatives, User Experience architects, researchers, designers and content creators. I am passionate in empowering my teams to creete transfomative work, be it in mapping a user journey or on a production shoot capturing content. My role on the team is like a coach - leading them to the championship game and winning. How do I do that? By being a huge proponent of having fun at work, because if it isn't fun, creativity will be stifled and along with it innovation. Shown below are some project highlights that were the results of the team having fun.</div></p> 
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Toyota Digital</div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="first"><img ref={el => timage1 = el} width="100%" src={Img1}  /></div>
         </div>
         <div className="b42"></div>   
         <div className="b43"></div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota2">
             
             <div className="second"><img ref={el => timage2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => timage3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => timage4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => timage5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => timage6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => timage7 = el} width="100%" src={Img7} /></div>
             <div className="eight"><img ref={el => timage8 = el} width="100%" src={Img8}  /></div>
             <div className="nine"><img ref={el => timage9 = el} width="100%" src={Img9}  /></div>
             <div className="ten"><img ref={el => timage10 = el} width="100%" src={Img10}  /></div>
             {/* <div className="ten"><img ref={el => image10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => image11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => image12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             {/* <div className="thirteen"><img ref={el => image13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => image14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => image15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => image16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => image17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => image18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => image19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => image20 = el} width="100%" src={Img21}  /></div>  */}
   
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         {/* <div className="b66"></div> 
         <div className="b67"></div>
         <div className="b68"></div>
         <div className="b69"></div>   
         <div className="b70"></div>

         <div className="b71"></div>

         <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div> */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/jordancom'>Back<p>Jordan.com</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/mfa'>Next<p>Toyota, MFA</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Toyotacom;