import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";


// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/jordancom/hero.jpg";
import Img1 from "../images/jordancom/01.jpg";
import Img2 from "../images/jordancom/02.jpg";
import Img3 from "../images/jordancom/03.jpg";
import Img4 from "../images/jordancom/04.jpg";
import Img5 from "../images/jordancom/05.jpg";
import Img6 from "../images/jordancom/06.jpg";
import Img7 from "../images/jordancom/07.jpg";
import Img8 from "../images/jordancom/08.jpg";
// import Img9 from "../images/jordancom/09.jpg";
// import Img10 from "../images/jordancom/10.jpg";
// import Img10 from "../images/toyota/10.jpg";
// import Img11 from "../images/toyota/11.jpg";
// import Img12 from "../images/toyota/12.jpg";
// import Img13 from "../images/toyota/13.jpg";
// import Img14 from "../images/toyota/14.jpg";
// import Img15 from "../images/toyota/15.jpg";
// import Img16 from "../images/toyota/16.jpg";
// import Img17 from "../images/toyota/17.jpg";
// import Img18 from "../images/toyota/18.jpg";
// import Img19 from "../images/toyota/dancegirl.gif";
// import Img20 from "../images/toyota/danceman.gif";
// import Img21 from "../images/toyota/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Jordancom = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let jcomimage1 = useRef(null);
    let jcomimage2 = useRef(null);
    let jcomimage3 = useRef(null);
    let jcomimage4 = useRef(null);
    let jcomimage5 = useRef(null);
    let jcomimage6 = useRef(null);
    let jcomimage7 = useRef(null);
    let jcomimage8 = useRef(null);

    
       

    let jcomimagereveal = CSSRulePlugin.getRule(".images:after");
    let jcomimagereveal1 = CSSRulePlugin.getRule(".jfirst:after");
    let jcomimagereveal2 = CSSRulePlugin.getRule(".jsecond:after");
    let jcomimagereveal3 = CSSRulePlugin.getRule(".jthird:after");
    let jcomimagereveal4 = CSSRulePlugin.getRule(".jfourth:after");
    let jcomimagereveal5 = CSSRulePlugin.getRule(".jfifth:after");
    let jcomimagereveal6 = CSSRulePlugin.getRule(".jsixth:after");
    let jcomimagereveal7 = CSSRulePlugin.getRule(".jseventh:after");
    let jcomimagereveal8 = CSSRulePlugin.getRule(".jeight:after");


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .space41 ", {
        scrollTrigger: {
            trigger : ".b41", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(jcomimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(jcomimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".jfirst", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(jcomimage1, 1.4, {
            scrollTrigger: {
                trigger : ".jfirst", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(jcomimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".jsecond", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(jcomimage2, 1.4, {
            scrollTrigger: {
                trigger : ".jsecond", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(jcomimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".jthird", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(jcomimage3, 1.4, {
                scrollTrigger: {
                    trigger : ".jthird", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(jcomimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".jfourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(jcomimage4, 1.4, {
                scrollTrigger: {
                    trigger : ".jfourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(jcomimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".jfifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(jcomimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".jfifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(jcomimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".jsixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(jcomimage6, 1.4, {
                    scrollTrigger: {
                        trigger : ".jsixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(jcomimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".jseventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(jcomimage7, 1.4, {
                        scrollTrigger: {
                            trigger : ".jseventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(jcomimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".jeight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(jcomimage8, 1.4, {
    scrollTrigger: {
        trigger : ".jeight", 
        start : "top 60%",
        // markers : true,
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  






            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">EXPERIENCE DESIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Jordan Digital</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
             <div className="title">AGENCY</div>
                    <div className="name">AKQA</div>                    
                    <div className="title">Group Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Design Director</div>
                    <div className="name">Hugo Gomez</div>
                    
                    <div className="title">Associate Creative Director</div>
                    <div className="name">Eamonn Dixon, Dan Grech, Dave Knoph, Dan Peters</div>
                    <div className="title">UX Director</div>
                    <div className="name">Zarni Ko</div>
                    <div className="title">Art Director</div>
                    <div className="name">Chi-Chi Bello, Dave Ruiz</div>
                    <div className="title">Copywriter</div>
                    <div className="name">Will Sands</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">At Jordan I lead a team of creatives that produce campaigns and .com work for both North America and global markets. It is this mix of campaign and platform work that makes this role challenging and exciting.</div>
               <p><div className="headlinebrandtext">The day to day is a constant flux of managing teams, clients and getting into sparing and debating sessions with creatives. The latter, is the part I enjoy the most, I love to see a nugget of an idea turn into gold. Shown below are some of the work that came out of those sessions. </div></p> 
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Jordan Digital</div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="jfirst"><img ref={el => jcomimage1 = el} width="100%" src={Img1}  /></div>
         </div>
         <div className="b42"></div>   
         <div className="b43"></div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota2">
             
             <div className="jsecond"><img ref={el => jcomimage2 = el} width="100%" src={Img2}  /></div>
             <div className="jthird"><img ref={el => jcomimage3 = el} width="100%" src={Img3}  /></div>

             <div className="jfourth"><img ref={el => jcomimage4 = el} width="100%" src={Img4}  /></div>
             <div className="jfifth"><img ref={el => jcomimage5 = el} width="100%" src={Img5}  /></div>
             <div className="jsixth"><img ref={el => jcomimage6 = el} width="100%" src={Img6}  /></div>
             <div className="jseventh"><img ref={el => jcomimage7 = el} width="100%" src={Img7} /></div>
             <div className="jeight"><img ref={el => jcomimage8 = el} width="100%" src={Img8}  /></div>
             {/* <div className="ten"><img ref={el => jcomimage10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => jcomimage11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => jcomimage12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             {/* <div className="thirteen"><img ref={el => jcomimage13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => jcomimage14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => jcomimage15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => jcomimage16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => jcomimage17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => jcomimage18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => jcomimage19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => jcomimage20 = el} width="100%" src={Img21}  /></div>  */}
   
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         {/* <div className="b66"></div> 
         <div className="b67"></div>
         <div className="b68"></div>
         <div className="b69"></div>   
         <div className="b70"></div>

         <div className="b71"></div>

         <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div> */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/toyota'>Back<p>Toyota TIQ</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/toyotacom'>Next<p>Toyota.com</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Jordancom;