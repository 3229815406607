import React, {useState, useEffect} from "react";
import { withRouter, Link } from "react-router-dom";
import Hamburger from './Hamburger'

const green = '#39D1B4';
const yellow = '#FFD712';

const Header = ({history}) => {
// state for menu button
const [state, setState] = useState ({
  initial: false,
  clicked: null,
  menuName: 'Dwayne',
  menuSurname: 'Koh',


})
// state for disabled button
const [disabled,setDisabled] = useState(false);

//use effect for page changes.

useEffect (() => {
  //listen for page changes
  history.listen(() =>{
    setState({clicked: false,  menuName: 'Dwayne', menuSurname: 'Koh'})

  });
})

const handleMenu = () => {
  disableMenu();
  if (state.initial === false){
    setState({
      initial: null,
      clicked: true,
      menuName: 'Dwayne', 
      menuSurname: 'x'

    });
    console.log(1);
  } else if (state.clicked === true){
    setState({
      clicked: !state.clicked,
      menuName: 'Dwayne',
      menuSurname: 'koh'


    });
    console.log(2);
  } else if (state.clicked === false){
    setState({
      clicked: !state.clicked,
      menuName: 'Dwayne', 
      menuSurname: 'x'
      

    });
    console.log(3);
  }
};



//determine if oru button should be disabled
const disableMenu = () => {
  setDisabled(!disabled);
  setTimeout(() => {
    setDisabled(false);
  }, 1200);
};



  return <header>
    <div className="container">
      {/* <div className="wrapper"> */}
        <div className="inner-header">
            <div className="logo">
              <Link to=''>Welcome<br/>to</Link>
            </div>
            <div className="middle">
              <a href="http://cargocollective.com/dwayne" target="_blank" rel="noopener noreferrer">The playground<br/>of</a>
            </div>
            <div className="menu">
                <button disabled={disabled} onClick={handleMenu}>
                    <div> {state.menuName}<br/>{state.menuSurname}</div>
                </button>
             </div>
         </div>
      {/* </div> */}
    </div>
    <Hamburger state={state} />
  </header>;
};

export default withRouter(Header);

export function function2() {
  console.log('hej')
}