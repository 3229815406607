import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/levis/hero.jpg";
import Img1 from "../images/levis/01.jpg";
import Img2 from "../images/levis/02.jpg";
import Img3 from "../images/levis/03.jpg";
import Img4 from "../images/levis/04.jpg";
import Img5 from "../images/levis/05.jpg";
import Img6 from "../images/levis/06.jpg";
import Img7 from "../images/levis/07.jpg";
import Img8 from "../images/levis/08.jpg";
import Img9 from "../images/levis/09.jpg";
import Img10 from "../images/levis/10.png";
// import Img10 from "../images/levis/10.jpg";
// import Img11 from "../images/levis/11.jpg";
// import Img12 from "../images/levis/12.jpg";
// import Img13 from "../images/levis/13.jpg";
// import Img14 from "../images/levis/14.jpg";
// import Img15 from "../images/levis/15.jpg";
// import Img16 from "../images/levis/16.jpg";
// import Img17 from "../images/levis/17.jpg";
// import Img18 from "../images/levis/18.jpg";
// import Img19 from "../images/levis/dancegirl.gif";
// import Img20 from "../images/levis/danceman.gif";
// import Img21 from "../images/levis/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Levis = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let levisimage1 = useRef(null);
    let levisimage2 = useRef(null);
    let levisimage3 = useRef(null);
    let levisimage4 = useRef(null);
    let levisimage5 = useRef(null);
    let levisimage6 = useRef(null);
    let levisimage7 = useRef(null);
    let levisimage8 = useRef(null);
    let levisimage9 = useRef(null);
    let levisimage10 = useRef(null);
    // let levisimage11 = useRef(null);
    // let levisimage12 = useRef(null);
    // let levisimage13 = useRef(null);
    // let levisimage14 = useRef(null);
    // let levisimage15 = useRef(null);
    // let levisimage16 = useRef(null);
    // let levisimage17 = useRef(null);    
    // let levisimage18 = useRef(null);
    // let levisimage19 = useRef(null);
    // let levisimage20 = useRef(null);
    
       

    let lilimagereveal = CSSRulePlugin.getRule(".images:after");
    let lilimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let lilimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let lilimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let lilimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let lilimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let lilimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let lilimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let lilimagereveal8 = CSSRulePlugin.getRule(".eight:after");

    let lilimagereveal9 = CSSRulePlugin.getRule(".nine:after");
    let lilimagereveal10 = CSSRulePlugin.getRule(".ten:after");
    let lilimagereveal11 = CSSRulePlugin.getRule(".eleven:after");
    let lilimagereveal12 = CSSRulePlugin.getRule(".twelve:after");
    let lilimagereveal13 = CSSRulePlugin.getRule(".thirteen:after");
    let lilimagereveal14 = CSSRulePlugin.getRule(".fourteen:after");
    let lilimagereveal15 = CSSRulePlugin.getRule(".fifthteen:after");
    let lilimagereveal16 = CSSRulePlugin.getRule(".sixteen:after");
    let lilimagereveal17 = CSSRulePlugin.getRule(".seventeen:after");
    let lilimagereveal18 = CSSRulePlugin.getRule(".eighteen:after");
    let lilimagereveal19 = CSSRulePlugin.getRule(".nineteen:after");
    let lilimagereveal20 = CSSRulePlugin.getRule(".twenty:after");
    // let lilimagereveal21 = CSSRulePlugin.getRule(".twentyone:after");    


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    
      gsap.from(" .video2 ", {
        scrollTrigger: {
            trigger : ".video2",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none none",
        },
        y: 200,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(lilimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(lilimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(levisimage1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(lilimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(levisimage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(lilimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(levisimage3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(lilimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(levisimage4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(lilimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(levisimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(lilimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(levisimage6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(lilimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(levisimage7, 1.4, {
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(lilimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(levisimage8, 1.4, {
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        // markers : true,
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


    gsap.to(lilimagereveal9, 1.4, { 
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
            ease: Power2.easeInOut, 
    })            
    
    gsap.from(levisimage9, 1.4, {
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
        
        gsap.to(lilimagereveal10, 1.4, { 
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 60%",
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
                ease: Power2.easeInOut, 
        })            
        
        gsap.from(levisimage10, 1.4, {
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  





            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND CAMPAIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Levis</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                    <div className="title">Group Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Creative Director</div>
                    <div className="name">Nick Marzano, Erin Robinson</div>
                    
                    <div className="title">Design Director</div>
                    <div className="name">Nate Scott</div>
                    <div className="title">Art Director</div>
                    <div className="name">Jack Douglas</div>
                    <div className="title">Copy Writer</div>
                    <div className="name">Carrie Dunn, Erik Gonzalez</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">Levis. Levi Strauss started the business at the 90 Sacramento Street address in San Francisco. He next moved the location to 62 Sacramento Street then 63 & 65 Sacramento Street. Because of its location, it takes pride in calling itself the 150 year old startup. My role was to help drive the culture that Levis has created from its heritage. That involved new ways of engaging consumers through music and fashion. I was tasked with running the digital platforms of Levis from the LiveInLevis campaigns, dot.com, Levis kids, the commuter series to Levis vintage collection. Becasue of this startup mentality, I always looked for ways to innovate and drive culture from both a Strategic and creative POV.</div>

            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Live in Levis </div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="space41">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/141505463"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b42"></div>   
         <div className="b43">    

        </div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota">
             <div className="first"><img ref={el => levisimage1 = el} width="100%" src={Img1}  /></div>
             <div className="second"><img ref={el => levisimage2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => levisimage3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => levisimage4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => levisimage5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => levisimage6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => levisimage7 = el} width="100%" src={Img7} /></div>
             <div className="eight"><img ref={el => levisimage8 = el} width="100%" src={Img8}  /></div>
             <div className="nine"><img ref={el => levisimage9 = el} width="100%" src={Img9}  /></div>
             <div className="ten"><img ref={el => levisimage10 = el} width="100%" src={Img10}  /></div>
             {/* <div className="ten"><img ref={el => levisimage10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => levisimage11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => levisimage12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             {/* <div className="thirteen"><img ref={el => levisimage13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => levisimage14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => levisimage15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => levisimage16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => levisimage17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => levisimage18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => levisimage19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => levisimage20 = el} width="100%" src={Img21}  /></div>  */}
   
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         <div className="jordanb66">
             <div className="video2">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/430187267"
        height='100%'
        width='100%'
        /> </div> 
        </div>
         <div className="jordanb67"></div>
         <div className="jordanb68"></div>
         <div className="jordanb69"></div>   
         <div className="jordanb70"></div>

         {/* <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div>  */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/asics'>Back<p>Asics</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/adidas'>Next<p>Adidas, UAO</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Levis;