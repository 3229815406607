import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";


import {
  staggerText,
  staggerReveal,
  fadeInUp,
  // handleHover,
  // handleHoverExit,
  staggerRevealClose
} from "./Animations";




const Hamburger = ({ state }) => {
  // Create varibles of our dom nodes
  let menuLayer = useRef(null);
  let reveal1 = useRef(null);
  let reveal2 = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);
  let line5 = useRef(null);
  let line6 = useRef(null);
  let line7 = useRef(null);
  let line8 = useRef(null);
  let line9 = useRef(null);
  let line10 = useRef(null);
  let line11 = useRef(null);
  let line12 = useRef(null);
  let info = useRef(null);

  useEffect(() => {



  

    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      staggerRevealClose(reveal2, reveal1);
      // Set menu to display none
      gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
      //Allow menu to have height of 100%
      gsap.to([reveal1, reveal2], {
        duration: 0,
        opacity: 1,
        height: "100%"
      });
      staggerReveal(reveal1, reveal2);
      fadeInUp(info);
      staggerText(line1, line2, line3, line4, line5, line6, line7, line8, line9, line10, line11, line12);
    }
  }, [state]);






  return <div ref={el => (menuLayer = el)} className='hamburger-menu'>
    <div
        ref={el => (reveal1 = el)}
        className='menu-secondary-background-color'></div>
      <div ref={el => (reveal2 = el)} className="menu-layer">
        {/* <div 
         
          className="menu-city-background">
            
        </div> */}
        <div className="container" >
          <div className="wrapper">
            
          
              <div ref={el => (info = el)} className="info">
                <div className="headline">Project<br/>Highlights</div>
              </div>
              
            
           

            <div className="brands">
            <div className="brandname">
                <p ref={el => (line1 = el)} ><Link to='/asics'>Asics</Link></p>
                <p ref={el => (line2 = el)} ><Link to='/adidas'>Adidas Originals</Link></p>
                <p ref={el => (line3 = el)} ><Link to='/converse'>Converse</Link></p>
                <p ref={el => (line4 = el)} ><Link to='/geancient'>General Electric</Link></p>
           </div>
           <div className="brandname">
                      
                        <p ref={el => (line5 = el)} ><Link to='/dolby'>Dolby</Link></p>
                        <p ref={el => (line6 = el)} ><Link to='/lvc'>Levis Vintage</Link></p>
                        <p ref={el => (line7 = el)} ><Link to='/levis'>Levis</Link></p>
                        <p ref={el => (line8 = el)} ><Link to='/toyota'>Toyota, TMQ</Link></p>
                 </div> 
                 <div className="brandname">
                      
                      <p ref={el => (line9 = el)} ><Link to='/jordan'>Jordan</Link></p>
                      <p ref={el => (line10 = el)} ><Link to='/mfa'>Toyota, MFA</Link></p>
                      <p ref={el => (line11 = el)} ><Link to='/conversespark'>Converse Spark</Link></p>
                      <p ref={el => (line12 = el)} ><Link to='/vans'>Vans</Link></p>
                </div> 
            </div>



            </div>
          </div>
        
      </div>
    
  </div>










};

export default Hamburger;
