import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/adidas/hero.jpg";
import Img1 from "../images/adidas/01.jpg";
import Img2 from "../images/adidas/02.jpg";
import Img3 from "../images/adidas/03.jpg";
import Img4 from "../images/adidas/04.jpg";
import Img5 from "../images/adidas/05.jpg";
import Img6 from "../images/adidas/06.jpg";
import Img7 from "../images/adidas/07.jpg";
import Img8 from "../images/adidas/08.jpg";
import Img9 from "../images/adidas/09.jpg";



// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Adidas = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let adidasimage1 = useRef(null);
    let adidasimage2 = useRef(null);
    let adidasimage3 = useRef(null);
    let adidasimage4 = useRef(null);
    let adidasimage5 = useRef(null);
    let adidasimage6 = useRef(null);
    let adidasimage7 = useRef(null);
    let adidasimage8 = useRef(null);
    let adidasimage9 = useRef(null);

       

    let adidasimagereveal = CSSRulePlugin.getRule(".images:after");
    let adidasimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let adidasimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let adidasimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let adidasimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let adidasimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let adidasimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let adidasimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let adidasimagereveal8 = CSSRulePlugin.getRule(".eight:after");
    let adidasimagereveal9 = CSSRulePlugin.getRule(".nine:after");



    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .space41 ", {
        scrollTrigger: {
            trigger : ".b41", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: -20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(adidasimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(adidasimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(adidasimage1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(adidasimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(adidasimage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(adidasimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(adidasimage3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(adidasimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(adidasimage4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(adidasimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(adidasimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(adidasimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(adidasimage6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(adidasimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(adidasimage7, 1.4, {
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(adidasimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(adidasimage8, 1.4, {
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        // markers : true,
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


    gsap.to(adidasimagereveal9, 1.4, { 
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 80%",
            // markers : true,
            toggleActions: "restart none none none",
        },
        
        width: "0%", 
            ease: Power2.easeInOut, 
    })            
    
    gsap.from(adidasimage9, 1.4, {
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 80%",
            toggleActions: "restart none none none",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
        






            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling, brandedcontent.nextSibling.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND CAMPAIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Adidas</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                    <div className="title">AGENCY</div>
                    <div className="name">TBWA</div>                 
                    <div className="title">Group Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Associate Creative Director</div>
                    <div className="name">Morris Lee, Jimmy Wang, 30ml, Sherry Shi</div>
                    
                    <div className="title">Creative Technologist</div>
                    <div className="name">Jeff</div>
                    <div className="title">Art Director</div>
                    <div className="name">QiHan</div>
                    <div className="title">Senior Copywriter</div>
                    <div className="name">HaiYang</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">Adidas Originals believes that deep inside all of us is a common thread connects us all together. That common thread is Originality. Introducing all originals #represent, a movement to unite a country through originality. </div>
                <p><div className="headlinebrandtext">We began in a van that toured the four corners of China.On the road, rockstar Eason Chen invited people to submit personal content that best represented themselves. Everything collected was included in China’s first crowd sourced music video.</div></p>
                <p><div className="headlinebrandtext">In-store kiosks, editorials and outdoor print helped spread the message of unity, and drive participation.Music lovers of all types and sizes lined up across the country to share their Originality for the video. And in the end, celebrate together how originality unites us all.</div></p>                

            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">All Originals Represent</div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="space41">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/57350039"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b42"></div>   
         <div className="b43">    

        </div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota">
             <div className="first"><img ref={el => adidasimage1 = el} width="100%" src={Img1}  /></div>
             <div className="second"><img ref={el => adidasimage2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => adidasimage3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => adidasimage4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => adidasimage5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => adidasimage6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => adidasimage7 = el} width="100%" src={Img7} /></div>
             <div className="eight"><img ref={el => adidasimage8 = el} width="100%" src={Img8}  /></div>
             <div className="nine"><img ref={el => adidasimage9 = el} width="100%" src={Img9}  /></div>
  
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         {/* <div className="b66"></div> 
         <div className="b67"></div>
         <div className="b68"></div>
         <div className="b69"></div>   
         <div className="b70"></div>

         <div className="b71"></div>

         <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div> */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/levis'>Back<p>Levis</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/converse'>Next<p>Converse</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>                 
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Adidas;