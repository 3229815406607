import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/geancient/hero.jpg";
import Img1 from "../images/geancient/01.jpg";
import Img2 from "../images/geancient/02.jpg";
import Img3 from "../images/geancient/03.jpg";
import Img4 from "../images/geancient/04.jpg";
import Img5 from "../images/geancient/05.jpg";
import Img6 from "../images/geancient/06.jpg";
import Img7 from "../images/geancient/07.jpg";
import Img8 from "../images/geancient/08.jpg";
// import Img9 from "../images/geancient/09.jpg";
// import Img10 from "../images/geancient/10.png";
// import Img10 from "../images/geancient/10.jpg";
// import Img11 from "../images/geancient/11.jpg";
// import Img12 from "../images/geancient/12.jpg";
// import Img13 from "../images/geancient/13.jpg";
// import Img14 from "../images/geancient/14.jpg";
// import Img15 from "../images/geancient/15.jpg";
// import Img16 from "../images/geancient/16.jpg";
// import Img17 from "../images/geancient/17.jpg";
// import Img18 from "../images/geancient/18.jpg";
// import Img19 from "../images/geancient/dancegirl.gif";
// import Img20 from "../images/geancient/danceman.gif";
// import Img21 from "../images/geancient/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Geancient = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let ancient1 = useRef(null);
    let ancient2 = useRef(null);
    let ancient3 = useRef(null);
    let ancient4 = useRef(null);
    let ancient5 = useRef(null);
    let ancient6 = useRef(null);
    let ancient7 = useRef(null);
    let ancient8 = useRef(null);
    // let ancient9 = useRef(null);
    // let ancient10 = useRef(null);
    // let ancient11 = useRef(null);
    // let ancient12 = useRef(null);
    // let ancient13 = useRef(null);
    // let ancient14 = useRef(null);
    // let ancient15 = useRef(null);
    // let ancient16 = useRef(null);
    // let ancient17 = useRef(null);    
    // let ancient18 = useRef(null);
    // let ancient19 = useRef(null);
    // let ancient20 = useRef(null);
    
       

    let ancientimagereveal = CSSRulePlugin.getRule(".images:after");
    let ancientimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let ancientimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let ancientimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let ancientimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let ancientimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let ancientimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let ancientimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let ancientimagereveal8 = CSSRulePlugin.getRule(".eight:after");

    // let ancientimagereveal9 = CSSRulePlugin.getRule(".nine:after");
    // let ancientimagereveal10 = CSSRulePlugin.getRule(".ten:after");
    let ancientimagereveal11 = CSSRulePlugin.getRule(".eleven:after");
    let ancientimagereveal12 = CSSRulePlugin.getRule(".twelve:after");
    let ancientimagereveal13 = CSSRulePlugin.getRule(".thirteen:after");
    let ancientimagereveal14 = CSSRulePlugin.getRule(".fourteen:after");
    let ancientimagereveal15 = CSSRulePlugin.getRule(".fifthteen:after");
    let ancientimagereveal16 = CSSRulePlugin.getRule(".sixteen:after");
    let ancientimagereveal17 = CSSRulePlugin.getRule(".seventeen:after");
    let ancientimagereveal18 = CSSRulePlugin.getRule(".eighteen:after");
    let ancientimagereveal19 = CSSRulePlugin.getRule(".nineteen:after");
    let ancientimagereveal20 = CSSRulePlugin.getRule(".twenty:after");
    // let ancientimagereveal21 = CSSRulePlugin.getRule(".twentyone:after");    


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    
      gsap.from(" .video2 ", {
        scrollTrigger: {
            trigger : ".video2",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none none",
        },
        y: 200,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(ancientimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(ancientimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(ancient1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(ancientimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(ancient2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(ancientimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(ancient3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(ancientimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(ancient4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(ancientimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(ancient5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(ancientimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(ancient6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(ancientimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(ancient7, 1.4, {
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(ancientimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(ancient8, 1.4, {
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        // markers : true,
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


    // gsap.to(ancientimagereveal9, 1.4, { 
    //     scrollTrigger: {
    //         trigger : ".nine", 
    //         start : "top 60%",
    //         toggleActions: "restart none none reverse",
    //     },
        
    //     width: "0%", 
    //         ease: Power2.easeInOut, 
    // })            
    
    // gsap.from(ancient9, 1.4, {
    //     scrollTrigger: {
    //         trigger : ".nine", 
    //         start : "top 60%",
    //         // markers : true,
    //         toggleActions: "restart none none reverse",
    //     },
    //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
        
    //     gsap.to(ancientimagereveal10, 1.4, { 
    //         scrollTrigger: {
    //             trigger : ".ten", 
    //             start : "top 60%",
    //             toggleActions: "restart none none reverse",
    //         },
            
    //         width: "0%", 
    //             ease: Power2.easeInOut, 
    //     })            
        
    //     gsap.from(ancient10, 1.4, {
    //         scrollTrigger: {
    //             trigger : ".ten", 
    //             start : "top 60%",
    //             // markers : true,
    //             toggleActions: "restart none none reverse",
    //         },
    //         scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  





            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND CAMPAIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">GE</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
             <div className="title">Group Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Associate Creative Director</div>
                    <div className="name">Jimmy Wang, 30ml, Sherry Shi</div>
                    
                    <div className="title">Creative Technologist</div>
                    <div className="name">Jeff</div>
                    <div className="title">Art Director</div>
                    <div className="name">QiHan</div>
                    <div className="title">Senior Copywriter</div>
                    <div className="name">HaiYang</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">Time-traveling animated tributes to four world-changing Chinese inventions.The brief was to showcase GE's technology to the Chinese audience. Our solution was to create a story that draws from the rich heritage of China. Namely the chinese inventions of Paper, Wax, Silk, Porcelain. Each story was executed in that particular style. To do this, the team collaborated closely to ensure the mix of photography and 3D and 2D illustrations integrated seamlessly together. The production of the site and PORCELAIN was by Ars Thanesa. While paper was created by ACNE and WAX by Ilovedust.</div>
                <p><div className="headlinebrandtext">Most importantly, we delivered the stories of GE's innovative work on the environment and China to Chinese government officials and C-level executives and inspired them to work with GE for the future of China.</div></p>                

            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Ancient Inventions </div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="space41">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/57425592"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b42"></div>   
         <div className="b43">    

        </div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota">
             <div className="first"><img ref={el => ancient1 = el} width="100%" src={Img1}  /></div>
             <div className="second"><img ref={el => ancient2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => ancient3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => ancient4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => ancient5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => ancient6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => ancient7 = el} width="100%" src={Img7} /></div>
             <div className="eight"><img ref={el => ancient8 = el} width="100%" src={Img8}  /></div>
             {/* <div className="ten"><img ref={el => ancient10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => ancient11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => ancient12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             {/* <div className="thirteen"><img ref={el => ancient13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => ancient14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => ancient15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => ancient16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => ancient17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => ancient18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => ancient19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => ancient20 = el} width="100%" src={Img21}  /></div>  */}
   
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         <div className="jordanb66">
             <div className="video2">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/53848415"
        height='100%'
        width='100%'
        /> </div> 
        </div>
         <div className="jordanb67"></div>
         <div className="jordanb68"></div>
         <div className="jordanb69"></div>   
         <div className="jordanb70"></div>

         {/* <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div>  */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/conversespark'>Back<p>Converse Spark</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/vans'>Next<p>Vans</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Geancient;