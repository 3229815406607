import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/jordan/hero.jpg";
import jordan from "../images/jordan/Jordan01.jpg";
import Img1 from "../images/jordan/01.jpg";
import Img2 from "../images/jordan/02.jpg";
import Img3 from "../images/jordan/03.jpg";
import Img4 from "../images/jordan/04.jpg";
import Img5 from "../images/jordan/05.jpg";
import Img6 from "../images/jordan/06.gif";
import Img7 from "../images/jordan/07.jpg";
import Img8 from "../images/jordan/08.jpg";
import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Jordan = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let tlsimage = useRef(null);
    let tlsimage2 = useRef(null);
    let tlsimage3 = useRef(null);
    // let tlsimage4 = useRef(null);
    let tlsimage5 = useRef(null);
    let tlsimage6 = useRef(null);
    let tlsimage7 = useRef(null);
    let tlsimage8 = useRef(null);
    let tlsimageReveal = CSSRulePlugin.getRule(".images:after");
    // let tlsimageReveal1 = CSSRulePlugin.getRule(".first:after");
    let tlsimageReveal2 = CSSRulePlugin.getRule(".second:after");
    let tlsimageReveal3 = CSSRulePlugin.getRule(".third:after");
    let tlsimageReveal4 = CSSRulePlugin.getRule(".fourth:after");
    let tlsimageReveal5 = CSSRulePlugin.getRule(".fifth:after");
    let tlsimageReveal6 = CSSRulePlugin.getRule(".sixth:after");
    let tlsimageReveal7 = CSSRulePlugin.getRule(".seventh:after");
    let tlsimageReveal8 = CSSRulePlugin.getRule(".eight:after");


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


    gsap.from(" .headlinebrandtext1, .headlinecategory2 ", {
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none none",
        },
        y: 400, ease: Power3.easeOut, opacity: 0, duration: 2 });

        

        gsap.from(" .headlinecategoryVideo ", {
            scrollTrigger: {
                trigger : ".b36", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            y: 400, ease: Power3.easeOut, opacity: 0, duration: 2 });        

      gsap.from(" .space41 ", {
        scrollTrigger: {
            trigger : ".space41",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .video2 ", {
        scrollTrigger: {
            trigger : ".video2",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none none",
        },
        y: 200,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      //footer animaiton 
      //
      //
      gsap.from(" .horizontalline .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    //   Footer Animation
    //   
    //   







//IMages animation      

    gsap.to(tlsimageReveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })

    gsap.from(tlsimage, 1.4, {
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})      
        
        gsap.to(tlsimageReveal2, 1.4, { 
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(tlsimage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            gsap.to(tlsimageReveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(tlsimage3, 1.4, {
            scrollTrigger: {
                trigger : ".third", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(tlsimageReveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            // gsap.from(tlsimage4, 1.4, {
            //     scrollTrigger: {
            //         trigger : ".fourth", 
            //         start : "top 60%",
            //         // markers : true,
            //         toggleActions: "restart none none pause",
            //     },
            //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(tlsimageReveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(tlsimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(tlsimageReveal6, 1.4, { 
                scrollTrigger: {
                    trigger : ".sixth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(tlsimage6, 1.4, {
                scrollTrigger: {
                    trigger : ".sixth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(tlsimageReveal7, 1.4, { 
                    scrollTrigger: {
                        trigger : ".seventh", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                    ease: Power2.easeInOut, 
                })            
    
                gsap.from(tlsimage7, 1.4, {
                    scrollTrigger: {
                        trigger : ".seventh", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(tlsimageReveal8, 1.4, { 
                        scrollTrigger: {
                            trigger : ".eight", 
                            start : "top 60%",
                            toggleActions: "restart  none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(tlsimage8, 1.4, {
                        scrollTrigger: {
                            trigger : ".eight", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none none",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND ACTIVATION</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Jordan</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                     <div className="title">AGENCY</div>
                    <div className="name">AKQA</div>                    
                    <div className="title">Group Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Design Director</div>
                    <div className="name">Hugo Gomez</div>
                    
                    <div className="title">Associate Creative Director</div>
                    <div className="name">Eamonn Dixon, Dan Grech, Dave Knoph, Dan Peters</div>
                    <div className="title">UX Director</div>
                    <div className="name">Zarni Ko</div>
                    <div className="title">Art Director</div>
                    <div className="name">Chi-Chi Bello, Dave Ruiz</div>
                    <div className="title">Copywriter</div>
                    <div className="name">Will Sands</div>
            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">The Jordan brand is the rare few that sits at the intersection of culture and sports, it is the brand unleashed by the G.O.A.T (Greatest Of All Time) - Jordan. My role as the global digital lead was to push the boundaries on digital communications for the brand and push the creative output across all digital properties.</div>
                <div className="headlinebrandtext">
                <p>At Jordan, I led teams that produce campaigns and .com work for both North America and global markets. It is this mix of campaign and platform work that makes this role challenging and exciting. The day to day is a constant flux of managing teams, clients and getting into sparing/debating sessions with creatives. The latter, is the part I enjoy the most, I love to see a nugget of an idea turn into gold. Shown below are some of the work that came out of those sessions. The Last Shot won Gold and 4 Silvers at Cannes-lions and 11 awards at the OneShow.</p>

                </div>
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="jordanb31"><div className="headlinecategoryVideo">The Last Shot</div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36">
         <div className="headlinebrandtext1">Step into MJ’s shoes. Step back in time. At NBA All-Star Weekend in NYC, we gave people a chance to trial the brand’s latest performance shoes and take one of MJ’s high-pressure, championship-winning buzzer beaters. ‘The Last Shot’ is a fully immersive and interactive LED half court that transforms to Michael Jordan’s greatest moments – complete with era-specific crowds and iconic NBA and NCAA courts.</div>     
             
        </div>  
         <div className="b37"></div> 
         <div className="b38 images"><img ref={el => tlsimage = el} width="100%" src={jordan}  /></div>
         <div className="b39"></div>
         <div className="b40"></div>   

         <div className="jordanb41">
         <div className="space41">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/131627635"
        height='100%'
        width='100%'
        /> </div>



         </div>
         <div className="b42"></div>   
         <div className="b43"></div> 
         <div className="b44"></div>   
         <div className="b45"></div>

         <div className="b46 images2">
             <div className="imageStack second"><img ref={el => tlsimage2 = el} width="100%" src={Img1}  /></div>
             <div className="imageStack third"><img ref={el => tlsimage3 = el} width="100%" src={Img2}  /></div>
             <div className="imageStack fourth"><img width="50%" src={Img3}  /><img width="50%" src={Img4}  /></div>
             <div className="imageStack fifth"><img ref={el => tlsimage5 = el} width="100%" src={Img5}  /></div>
             <div className="imageStack sixth"><img ref={el => tlsimage6 = el} width="100%" src={Img6}  /></div>
             <div className="imageStack seventh"><img ref={el => tlsimage7 = el} width="100%" src={Img7}  /></div>
             <div className="imageStack eight"><img ref={el => tlsimage8 = el} width="100%" src={Img8}  /></div>           
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div> */}

         {/* <div className="56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div> */}

{/* 
         <div className="b61"></div>

         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         <div className="jordanb66">
             <div className="video2">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/172275782"
        height='100%'
        width='100%'
        /> </div> 
        </div>
         <div className="jordanb67"></div>
         <div className="jordanb68"></div>
         <div className="jordanb69"></div>   
         <div className="jordanb70"></div>

         {/* <div className="b71"></div>
         <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div> */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/dolby'>Back<p>Dolby</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/toyota'>Next<p>Toyota TIQ</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Jordan;