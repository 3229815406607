import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/converse/hero.jpg";
import Img1 from "../images/converse/01.jpg";
import Img2 from "../images/converse/02.jpg";
import Img3 from "../images/converse/03.jpg";
import Img4 from "../images/converse/04.jpg";
import Img5 from "../images/converse/05.jpg";
import Img6 from "../images/converse/06.jpg";
import Img7 from "../images/converse/07.jpg";
import Img8 from "../images/converse/08.jpg";
import Img9 from "../images/converse/09.jpg";
import Img10 from "../images/converse/10.png";
// import Img10 from "../images/converse/10.jpg";
// import Img11 from "../images/converse/11.jpg";
// import Img12 from "../images/converse/12.jpg";
// import Img13 from "../images/converse/13.jpg";
// import Img14 from "../images/converse/14.jpg";
// import Img15 from "../images/converse/15.jpg";
// import Img16 from "../images/converse/16.jpg";
// import Img17 from "../images/converse/17.jpg";
// import Img18 from "../images/converse/18.jpg";
// import Img19 from "../images/converse/dancegirl.gif";
// import Img20 from "../images/converse/danceman.gif";
// import Img21 from "../images/converse/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Converse = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let converse1 = useRef(null);
    let converse2 = useRef(null);
    let converse3 = useRef(null);
    let converse4 = useRef(null);
    let converse5 = useRef(null);
    let converse6 = useRef(null);
    let converse7 = useRef(null);
    let converse8 = useRef(null);
    let converse9 = useRef(null);
    let converse10 = useRef(null);
    // let converse11 = useRef(null);
    // let converse12 = useRef(null);
    // let converse13 = useRef(null);
    // let converse14 = useRef(null);
    // let converse15 = useRef(null);
    // let converse16 = useRef(null);
    // let converse17 = useRef(null);    
    // let converse18 = useRef(null);
    // let converse19 = useRef(null);
    // let converse20 = useRef(null);
    
       

    let converseimagereveal = CSSRulePlugin.getRule(".images:after");
    let converseimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let converseimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let converseimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let converseimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let converseimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let converseimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let converseimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let converseimagereveal8 = CSSRulePlugin.getRule(".eight:after");

    let converseimagereveal9 = CSSRulePlugin.getRule(".nine:after");
    let converseimagereveal10 = CSSRulePlugin.getRule(".ten:after");
    let converseimagereveal11 = CSSRulePlugin.getRule(".eleven:after");
    let converseimagereveal12 = CSSRulePlugin.getRule(".twelve:after");
    let converseimagereveal13 = CSSRulePlugin.getRule(".thirteen:after");
    let converseimagereveal14 = CSSRulePlugin.getRule(".fourteen:after");
    let converseimagereveal15 = CSSRulePlugin.getRule(".fifthteen:after");
    let converseimagereveal16 = CSSRulePlugin.getRule(".sixteen:after");
    let converseimagereveal17 = CSSRulePlugin.getRule(".seventeen:after");
    let converseimagereveal18 = CSSRulePlugin.getRule(".eighteen:after");
    let converseimagereveal19 = CSSRulePlugin.getRule(".nineteen:after");
    let converseimagereveal20 = CSSRulePlugin.getRule(".twenty:after");
    // let converseimagereveal21 = CSSRulePlugin.getRule(".twentyone:after");    


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    
      gsap.from(" .video2 ", {
        scrollTrigger: {
            trigger : ".video2",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(converseimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(converseimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(converse1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(converseimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(converse2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(converseimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(converse3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(converseimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(converse4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(converseimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(converse5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(converseimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(converse6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(converseimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(converse7, 1.4, {
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(converseimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(converse8, 1.4, {
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        // markers : true,
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


    gsap.to(converseimagereveal9, 1.4, { 
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
            ease: Power2.easeInOut, 
    })            
    
    gsap.from(converse9, 1.4, {
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
        
        gsap.to(converseimagereveal10, 1.4, { 
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 80%",
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
                ease: Power2.easeInOut, 
        })            
        
        gsap.from(converse10, 1.4, {
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 80%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  





            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND CAMPAIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Converse</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                    <div className="title">Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    <div className="title">Senior Creative</div>
                    <div className="name">Julie Liu</div>
                    <div className="name">Morris Lee, Tea Qiu</div>
                    <div className="title">User Experience</div>
                    <div className="title">Copywriter</div>
                    <div className="name">Zebra</div>
                    <div className="title">UX</div>
                    <div className="name">Adrian Lai</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">Converse’s brand vision is to inspire originality. All originals are rebels, but it is defined as optimistic rebellion. They share the instinctive believe that the world can be a better place, and that its in everyone’s power to make it that way.</div>
                <p><div className="headlinebrandtext">Throughout its history it has enjoyed a strong connection with independent music. Our starting point for Converse in China was to create an authentic connection to the Chinese independent music scene. To inspire originality and empower youths. Youths that are loud, some quiet, but they are all on their own path. And they all believe they can create something better. The work we produced was to drive that empowerment. The campaigns I oversaw were launched in the Chinese and South East Asia/Asian market.</div></p>
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Converse Lyrics </div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="video640">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/430627911"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b42"></div>   
         <div className="b43">    

        </div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota">
             <div className="first"><img ref={el => converse1 = el} width="100%" src={Img1}  /></div>
             <div className="second"><img ref={el => converse2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => converse3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => converse4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => converse5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => converse6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => converse7 = el} width="100%" src={Img7} /></div>
             <div className="eight"><img ref={el => converse8 = el} width="100%" src={Img8}  /></div>
             <div className="nine"><img ref={el => converse9 = el} width="100%" src={Img9}  /></div>
             <div className="ten"><img ref={el => converse10 = el} width="100%" src={Img10}  /></div>
             {/* <div className="ten"><img ref={el => converse10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => converse11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => converse12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             {/* <div className="thirteen"><img ref={el => converse13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => converse14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => converse15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => converse16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => converse17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => converse18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => converse19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => converse20 = el} width="100%" src={Img21}  /></div>  */}
   
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}
{/* 
         <div className="jordanb66"></div>
         <div className="jordanb67"></div>
         <div className="jordanb68"></div>
         <div className="jordanb69"></div>   
         <div className="jordanb70"></div> */}

         {/* <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div>  */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/adidas'>Back<p>Adidas</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/conversespark'>Next<p>Converse, Spark</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Converse;