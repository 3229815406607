import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/dolby/hero.jpg";
import Img1 from "../images/dolby/01.jpg";
import Img1a from "../images/dolby/01a_logo.gif";
import Img2 from "../images/dolby/02.jpg";
import Img3 from "../images/dolby/03.jpg";
import Img4 from "../images/dolby/04.jpg";
import Img5 from "../images/dolby/05.jpg";
import Img6 from "../images/dolby/06.jpg";
import Img7 from "../images/dolby/07.jpg";
import Img8 from "../images/dolby/08.jpg";
import Img9 from "../images/dolby/09.jpg";
import Img10 from "../images/dolby/10.jpg";
import Img11 from "../images/dolby/11.jpg";
import Img12 from "../images/dolby/12.jpg";
import Img13 from "../images/dolby/13.jpg";
import Img14 from "../images/dolby/14.jpg";
import Img15 from "../images/dolby/15.jpg";
import Img16 from "../images/dolby/16.jpg";
import Img17 from "../images/dolby/17.jpg";
import Img18 from "../images/dolby/18.jpg";
import Img19 from "../images/dolby/dancegirl.gif";
import Img20 from "../images/dolby/danceman.gif";
import Img21 from "../images/dolby/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Dolby = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let dolbyimage2 = useRef(null);
    let dolbyimage3 = useRef(null);
    // let dolbyimage4 = useRef(null);
    let dolbyimage5 = useRef(null);
    let dolbyimage6 = useRef(null);
    // let dolbyimage7 = useRef(null);
    let dolbyimage8 = useRef(null);
    let dolbyimage9 = useRef(null);
    let dolbyimage10 = useRef(null);
    let dolbyimage11 = useRef(null);
    let dolbyimage12 = useRef(null);
    let dolbyimage13 = useRef(null);
    let dolbyimage14 = useRef(null);
    let dolbyimage15 = useRef(null);
    let dolbyimage16 = useRef(null);
    let dolbyimage17 = useRef(null);    
    let dolbyimage18 = useRef(null);
    let dolbyimage19 = useRef(null);
    let dolbyimage20 = useRef(null);
    
       

    let dolbyimagereveal = CSSRulePlugin.getRule(".images:after");
    // let dolbyimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let dolbyimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let dolbyimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let dolbyimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let dolbyimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let dolbyimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let dolbyimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let dolbyimagereveal8 = CSSRulePlugin.getRule(".eight:after");

    let dolbyimagereveal9 = CSSRulePlugin.getRule(".nine:after");
    let dolbyimagereveal10 = CSSRulePlugin.getRule(".ten:after");
    let dolbyimagereveal11 = CSSRulePlugin.getRule(".eleven:after");
    let dolbyimagereveal12 = CSSRulePlugin.getRule(".twelve:after");
    let dolbyimagereveal13 = CSSRulePlugin.getRule(".thirteen:after");
    let dolbyimagereveal14 = CSSRulePlugin.getRule(".fourteen:after");
    let dolbyimagereveal15 = CSSRulePlugin.getRule(".fifthteen:after");
    let dolbyimagereveal16 = CSSRulePlugin.getRule(".sixteen:after");
    let dolbyimagereveal17 = CSSRulePlugin.getRule(".seventeen:after");
    let dolbyimagereveal18 = CSSRulePlugin.getRule(".eighteen:after");
    let dolbyimagereveal19 = CSSRulePlugin.getRule(".nineteen:after");
    let dolbyimagereveal20 = CSSRulePlugin.getRule(".twenty:after");
    let dolbyimagereveal21 = CSSRulePlugin.getRule(".twentyone:after");    


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .headlinebrandtext2, .headlinecategory3 ", {
        scrollTrigger: {
            trigger : ".b61", 
            start : "top 90%",
            // //markers : true, 
            toggleActions: "restart none reverse reset",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });



//footer animaiton 
//
//
      gsap.from(" .horizontalline .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            //markers : true, 
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(dolbyimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
             //markers : true, 
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(dolbyimagereveal2, 1.4, { 
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                 //markers : true, 
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(dolbyimage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                 //markers : true, 
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            gsap.to(dolbyimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                     //markers : true, 
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(dolbyimage3, 1.4, {
            scrollTrigger: {
                trigger : ".third", 
                start : "top 60%",
                 //markers : true, 
               toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(dolbyimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                     //markers : true, 
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            // gsap.from(dolbyimage4, 1.4, {
            //     scrollTrigger: {
            //         trigger : ".fourth", 
            //         start : "top 60%",
            //         // //markers : true, 
            //        toggleActions: "restart none none reverse",
            //     },
            //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(dolbyimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                     //markers : true, 
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(dolbyimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                     //markers : true, 
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(dolbyimagereveal6, 1.4, { 
                scrollTrigger: {
                    trigger : ".sixth", 
                    start : "top 60%",
                     //markers : true, 
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(dolbyimage6, 1.4, {
                scrollTrigger: {
                    trigger : ".sixth", 
                    start : "top 60%",
                     //markers : true, 
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(dolbyimagereveal7, 1.4, { 
                    scrollTrigger: {
                        trigger : ".seventh", 
                        start : "top 60%",  
                        //markers : true,                 
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                // gsap.from(dolbyimage7, 1.4, {
                //     scrollTrigger: {
                //         trigger : ".seventh", 
                //         start : "top 60%",
                //         // //markers : true, 
                //        toggleActions: "restart none none reverse",
                //     },
                //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(dolbyimagereveal8, 1.4, { 
                        scrollTrigger: {
                            trigger : ".eight", 
                            start : "top 60%",
                            //markers : true, 
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(dolbyimage8, 1.4, {
                        scrollTrigger: {
                            trigger : ".eight", 
                            start : "top 60%",
                            //markers : true, 
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(dolbyimagereveal9, 1.4, { 
    scrollTrigger: {
        trigger : ".nine", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(dolbyimage9, 1.4, {
    scrollTrigger: {
        trigger : ".nine", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


gsap.to(dolbyimagereveal10, 1.4, { 
    scrollTrigger: {
        trigger : ".ten", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            
    
gsap.from(dolbyimage10, 1.4, {
    scrollTrigger: {
        trigger : ".ten", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  

gsap.to(dolbyimagereveal11, 1.4, { 
    scrollTrigger: {
        trigger : ".eleven", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            
    
gsap.from(dolbyimage11, 1.4, {
    scrollTrigger: {
        trigger : ".eleven", 
        start : "top 60%",
        // //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


gsap.to(dolbyimagereveal12, 1.4, { 
    scrollTrigger: {
        trigger : ".twelve", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            
    
gsap.from(dolbyimage12, 1.4, {
    scrollTrigger: {
        trigger : ".twelve", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  

gsap.to(dolbyimagereveal13, 1.4, { 
    scrollTrigger: {
        trigger : ".thirteen", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            
    
gsap.from(dolbyimage13, 1.4, {
    scrollTrigger: {
        trigger : ".thirteen", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})      


gsap.to(dolbyimagereveal14, 1.4, { 
    scrollTrigger: {
        trigger : ".fourteen", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            
    
gsap.from(dolbyimage14, 1.4, {
    scrollTrigger: {
        trigger : ".fourteen", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

    gsap.to(dolbyimagereveal15, 1.4, { 
        scrollTrigger: {
            trigger : ".fifthteen", 
            start : "top 60%",
            //markers : true, 
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
            ease: Power2.easeInOut, 
    })            
        
    gsap.from(dolbyimage15, 1.4, {
        scrollTrigger: {
            trigger : ".fifthteen", 
            start : "top 60%",
            //markers : true, 
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

gsap.to(dolbyimagereveal16, 1.4, { 
    scrollTrigger: {
        trigger : ".sixteen", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            
    
gsap.from(dolbyimage16, 1.4, {
    scrollTrigger: {
        trigger : ".sixteen", 
        start : "top 60%",
        //markers : true, 
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

gsap.to(dolbyimagereveal17, 1.4, { 
    scrollTrigger: { trigger : ".seventeen",  start : "top 60%", 
    //markers : true, 
    toggleActions: "restart none none reverse", },
    width: "0%", ease: Power2.easeInOut, })            
    
gsap.from(dolbyimage17, 1.4, {
    scrollTrigger: { trigger : ".seventeen", start : "top 60%", 
    //markers : true,  
    toggleActions: "restart none none reverse", }, scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
gsap.to(dolbyimagereveal18, 1.4, { 
    scrollTrigger: { trigger : ".eighteen",  start : "top 60%", 
    //markers : true, 
    toggleActions: "restart none none reverse", },
    width: "0%", ease: Power2.easeInOut, })            
    
gsap.from(dolbyimage18, 1.4, {
    scrollTrigger: { trigger : ".eighteen", start : "top 60%", 
    //markers : true,  
    toggleActions: "restart none none reverse", }, scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

gsap.to(dolbyimagereveal19, 1.4, { 
    scrollTrigger: { trigger : ".nineteen",  start : "top 60%",  
    //markers : true, 
    toggleActions: "restart none none reverse", },
    width: "0%", ease: Power2.easeInOut, })            
    
gsap.from(dolbyimage19, 1.4, {
    scrollTrigger: { trigger : ".nineteen", start : "top 60%", 
    //markers : true,  
    toggleActions: "restart none none reverse", }, scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

gsap.to(dolbyimagereveal20, 1.4, { 
    scrollTrigger: { trigger : ".twenty",  start : "top 70%", 
    //markers : true, 
    toggleActions: "restart none none reverse", },
    width: "0%", ease: Power2.easeInOut, })            
    
gsap.from(dolbyimage20, 1.4, {
    scrollTrigger: { trigger : ".twenty", start : "top 70%", 
    //markers : true,  
    toggleActions: "restart none none reverse", }, scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

  




            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id="topOfPage"></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND IDENTITY</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Dolby</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                     <div className="title">AGENCY</div>
                    <div className="name">Huge Inc</div>                    
                    <div className="title">Executive Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Design Director</div>
                    <div className="name">Adriel Nunes</div>
                    
                    <div className="title">Senior Copywriter</div>
                    <div className="name">Tu Vu</div>
                    <div className="title">Art Director</div>
                    <div className="name">Emily Hom-Nici, Lamon Bethel</div>

            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">The Dolby brand has a legacy. It represents quality, but it has disappeared into the background. Dolby's shift from an ingredient to experience brand, opens up an exciting new world in design language that will enhance it's connection with consumers. This new Brand ID articulates how the Dolby brand shows up in this new consumer space.</div>
                <div className="headlinebrandtext">
                <p>I re-envisioned the Dolby consumer brand to set the foundation for Dolbys' consumer products launching into the market. The current brand felt cold and detached from consumers; it lacked emotion and felt too corporate. My main goal for this redesign was to open the brand up and make it fun and appealing to new audiences. I pushed to give the brand a new color palette. Bright, bold colors are what is needed. The world has too many technology brands in the black and white color space. The world needs color! </p>

                </div>
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>   

         <div className="b41"></div>
         <div className="b42"></div>   
         <div className="b43"></div> 
         <div className="b44"></div>   
         <div className="b45"></div>

         <div className="b46 imagesdolby">
             <div className="second"><img ref={el => dolbyimage2 = el} width="100%" src={Img1}  /></div>
             <div className="third"><img ref={el => dolbyimage2 = el} width="100%" src={Img1a}  /></div>
             <div className="third"><img ref={el => dolbyimage3 = el} width="100%" src={Img2}  /></div>

             <div className="fourth"><img width="50%" src={Img3}  /><img width="50%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => dolbyimage5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => dolbyimage6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img width="50%" src={Img7}  /><img width="50%" src={Img8}  /></div>
             <div className="eight"><img ref={el => dolbyimage8 = el} width="100%" src={Img9}  /></div>

             <div className="nine"><img ref={el => dolbyimage9 = el} width="100%" src={Img10}  /></div>
             <div className="ten"><img ref={el => dolbyimage10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => dolbyimage11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => dolbyimage12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             <div className="thirteen"><img ref={el => dolbyimage13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => dolbyimage14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => dolbyimage15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => dolbyimage16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => dolbyimage17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => dolbyimage18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => dolbyimage19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => dolbyimage20 = el} width="100%" src={Img21}  /></div> 
            
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}

         {/* <div className="b66"></div> 
         <div className="b67"></div>
         <div className="b68"></div>
         <div className="b69"></div>   
         <div className="b70"></div>

         <div className="b71"></div>

         <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div> */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/vans'>Back<p>Vans</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/jordan'>Next<p>Jordan, TLS</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Dolby;