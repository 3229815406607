import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/asics/hero.jpg";
import asics2 from "../images/asics/asics2.jpg";
import Img1 from "../images/asics/01.jpg";
import Img2 from "../images/asics/02.jpg";
import Img3 from "../images/asics/03.jpg";
import Img4 from "../images/asics/04.jpg";
import Img5 from "../images/asics/05.jpg";
import Img6 from "../images/asics/06.jpg";
import Img7 from "../images/asics/07.jpg";
import Img8 from "../images/asics/08.jpg";
import Img9 from "../images/asics/09.jpg";
import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Asics = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let asicsimage = useRef(null);
    let asicsimage2 = useRef(null);
    let asicsimage3 = useRef(null);
    // let asicsimage4 = useRef(null);
    let asicsimage5 = useRef(null);
    let asicsimage6 = useRef(null);
    // let asicsimage7 = useRef(null);
    let asicsimage8 = useRef(null);
    let asicsimagereveal = CSSRulePlugin.getRule(".images:after");
    // let asicsimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let asicsimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let asicsimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let asicsimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let asicsimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let asicsimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let asicsimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let asicsimagereveal8 = CSSRulePlugin.getRule(".eight:after");


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


    gsap.from(" .headlinebrandtext1, .headlinecategory2 ", {
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400, ease: Power3.easeOut, opacity: 0, duration: 2 });


      gsap.from(" .headlinebrandtext2, .headlinecategory3 ", {
        scrollTrigger: {
            trigger : ".b61", 
            start : "top 90%",
            // markers : true,
            // scrub: true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .space61 ", {
        scrollTrigger: {
            trigger : ".b61",
            start : "top 70%",
            // markers : true,
            // scrub: true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .space66 ", {
        scrollTrigger: {
            trigger : ".b66",
            start : "top 70%",
            // scrub: true,
            toggleActions: "restart none none reverse",
        },
        y: 300,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

      gsap.from(" .space71 ", {
        scrollTrigger: {
            trigger : ".b66",
            start : "top 70%",
            // scrub: true,
            toggleActions: "restart none none reverse",
        },
        y: 200,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });      


      //footer animaiton 
      //
      //
      gsap.from(" .horizontalline .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    //   Footer Animation
    //   
    //   







//IMages animation      

    gsap.to(asicsimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })

    gsap.from(asicsimage, 1.4, {
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})      
        
        gsap.to(asicsimagereveal2, 1.4, { 
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(asicsimage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            gsap.to(asicsimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(asicsimage3, 1.4, {
            scrollTrigger: {
                trigger : ".third", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(asicsimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            // gsap.from(asicsimage4, 1.4, {
            //     scrollTrigger: {
            //         trigger : ".fourth", 
            //         start : "top 60%",
            //         // markers : true,
            //         toggleActions: "restart none none reverse",
            //     },
            //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(asicsimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(asicsimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(asicsimagereveal6, 1.4, { 
                scrollTrigger: {
                    trigger : ".sixth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(asicsimage6, 1.4, {
                scrollTrigger: {
                    trigger : ".sixth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(asicsimagereveal7, 1.4, { 
                    scrollTrigger: {
                        trigger : ".seventh", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                // gsap.from(asicsimage7, 1.4, {
                //     scrollTrigger: {
                //         trigger : ".seventh", 
                //         start : "top 60%",
                //         // markers : true,
                //         toggleActions: "restart none none reverse",
                //     },
                //     scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(asicsimagereveal8, 1.4, { 
                        scrollTrigger: {
                            trigger : ".eight", 
                            start : "top 80%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(asicsimage8, 1.4, {
                        scrollTrigger: {
                            trigger : ".eight", 
                            start : "top 80%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">BRAND CAMPAIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Asics</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                    <div className="title">Executive Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    
                    <div className="title">Creative Director</div>
                    <div className="name">Yagi Matt, Michael Sison, Joe Hagel</div>
                    
                    <div className="title">Design Director</div>
                    <div className="name">Doug Alves, Adriel Nueas</div>
                    <div className="title">Art Director</div>
                    <div className="name">Moria Stevenson, Laura Rodrigues</div>
                    <div className="title">Copywriter</div>
                    <div className="name">Tess maguire, Amanda Miller</div>
            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">In 2017, We were invited by ASICS to pitch for their global business. I led the pitch against SidLee and Hakuhodo and succeeded in winning the account. The brief to us was to relaunch the brand for the current generation with a focus on young millennials.</div>
                <div className="headlinebrandtext">
                <p>Anima Sana In Corpore Sano. Latin for “A sound mind in a sound body.” And also the acronym for ASICS. Mr. Onitsuka founded ASICS on the principle that movement energizes the mind and readies it for life. A sentiment that we found a modern-day way to express with I MOVE ME™. Below is the brand campaign we launched at the IAAF in London.</p>

                </div>
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="headlinecategory2">I Move Me</div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36">
         <div className="headlinebrandtext1">Why I Move Me?. Other then it having a close tie to "A sound mind in a Sound body", we needed a communication tagline that conveys not only self-discipline in sports but also how your mind is the only thing that can really move you. (I is for mind while Me is for the body) I love this line as it was not just about winning. If you want to move yourself to win a marathon go ahead, and if you want to move yourself to run around the block twice a week that is fine too. The line takes the pressure of winning (not everyone gets into sports to win) to moving. Because, when you move your body you move your life, and we have but one life to live.</div>     
             
        </div>  
         <div className="b37"></div> 
         <div className="b38 images"><img ref={el => asicsimage = el} width="100%" src={asics2}  /></div>
         <div className="b39"></div>
         <div className="b40"></div>   

         <div className="b41"></div>
         <div className="b42"></div>   
         <div className="b43"></div> 
         <div className="b44"></div>   
         <div className="b45"></div>

         <div className="b46 imagesasics">
             <div className="imageStack second"><img ref={el => asicsimage2 = el} width="100%" src={Img1}  /></div>
             <div className="imageStack third"><img ref={el => asicsimage3 = el} width="100%" src={Img2}  /></div>
             <div className="imageStack fourth"><img width="50%" src={Img3}  /><img width="50%" src={Img4}  /></div>
             <div className="imageStack fifth"><img ref={el => asicsimage5 = el} width="100%" src={Img5}  /></div>
             <div className="imageStack sixth"><img ref={el => asicsimage6 = el} width="100%" src={Img6}  /></div>
             <div className="imageStack seventh"><img width="50%" src={Img7}  /><img width="50%" src={Img8}  /></div>
             <div className="imageStack eight"><img ref={el => asicsimage8 = el} width="100%" src={Img9}  /></div>
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         <div className="b51" >
             <div className="headlinecategory3">Sole of Kobe</div>
             <div className="headlinebrandtext2">ASICS has long been an outsider brand, known for innovating at its own pace and in its own way. But many of the innovations we enjoy today in all athletic apparel have their roots in Kobe, starting from the philosophies and beliefs established by Kihachiro Onitsuka after the war. <p>The Sole of Kobe series tells the stories of these philosophies and how they lead to monumental moments in sports, in the news, and have had lasting effects on culture the world over. Here is the first three films in the series.</p></div>
         </div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"> 
            <div className="space61">
                <ReactPlayer className='react-player'
                url="https://vimeo.com/273811480"
                height='100%'
                width='100%'
                /></div>
        </div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div>

         <div className="b66">
             <div className="space66">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/273811731"
        height='100%'
        width='100%'
        /> </div>
        </div> 
         <div className="b67"></div>
         <div className="b68"></div>
         <div className="b69"></div>   
         <div className="b70"></div>

         <div className="b71">
             <div className="space71">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/274994933"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div>

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/lvc'>Back<p>Levis, LVC</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/levis'>Next<p>Levis, LIL</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Asics;