import React, {useRef, useEffect} from 'react';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { ScrollTrigger } from "gsap/src/ScrollTrigger";
import ReactPlayer from "react-player";

// import { Link } from "react-router-dom";
import "../brand.scss"
import hero from "../images/lvc/hero.png";
import Img1 from "../images/lvc/01.jpg";
import Img2 from "../images/lvc/02.jpg";
import Img3 from "../images/lvc/03.jpg";
import Img4 from "../images/lvc/04.png";
import Img5 from "../images/lvc/05.png";
import Img6 from "../images/lvc/06.jpg";
import Img7 from "../images/lvc/07.jpg";
import Img8 from "../images/lvc/08.jpg";
import Img9 from "../images/lvc/09.jpg";
import Img10 from "../images/lvc/10.jpg";
import Img11 from "../images/lvc/11.jpg";
import Img12 from "../images/lvc/12.jpg";
import Img13 from "../images/lvc/13.jpg";
// import Img14 from "../images/lvc/14.jpg";
// import Img15 from "../images/lvc/15.jpg";
// import Img16 from "../images/lvc/16.jpg";
// import Img17 from "../images/lvc/17.jpg";
// import Img18 from "../images/lvc/18.jpg";
// import Img19 from "../images/lvc/dancegirl.gif";
// import Img20 from "../images/lvc/danceman.gif";
// import Img21 from "../images/lvc/dolbyending.gif";


// import ReactPlayer from "react-player";
import CSSRulePlugin from "gsap/src/CSSRulePlugin";
import { TimelineLite, Power2, Power3 } from 'gsap';
import AnchorLink from 'react-anchor-link-smooth-scroll'


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);


const Lvc = () => {
    let content = useRef(null);
    let brandcontent = useRef(null);
    let team = useRef(null);
    let heroimage = useRef(null);
    let lvcimage1 = useRef(null);
    let lvcimage2 = useRef(null);
    let lvcimage3 = useRef(null);
    let lvcimage4 = useRef(null);
    let lvcimage5 = useRef(null);
    let lvcimage6 = useRef(null);
    let lvcimage7 = useRef(null);
    let lvcimage8 = useRef(null);
    let lvcimage9 = useRef(null);
    let lvcimage10 = useRef(null);
    let lvcimage11 = useRef(null);
    let lvcimage12 = useRef(null);
    let lvcimage13 = useRef(null);
    // let lvcimage14 = useRef(null);
    // let lvcimage15 = useRef(null);
    // let lvcimage16 = useRef(null);
    // let lvcimage17 = useRef(null);    
    // let lvcimage18 = useRef(null);
    // let lvcimage19 = useRef(null);
    // let lvcimage20 = useRef(null);
    
       

    let lvcimagereveal = CSSRulePlugin.getRule(".images:after");
    let lvcimagereveal1 = CSSRulePlugin.getRule(".first:after");
    let lvcimagereveal2 = CSSRulePlugin.getRule(".second:after");
    let lvcimagereveal3 = CSSRulePlugin.getRule(".third:after");
    let lvcimagereveal4 = CSSRulePlugin.getRule(".fourth:after");
    let lvcimagereveal5 = CSSRulePlugin.getRule(".fifth:after");
    let lvcimagereveal6 = CSSRulePlugin.getRule(".sixth:after");
    let lvcimagereveal7 = CSSRulePlugin.getRule(".seventh:after");
    let lvcimagereveal8 = CSSRulePlugin.getRule(".eight:after");

    let lvcimagereveal9 = CSSRulePlugin.getRule(".nine:after");
    let lvcimagereveal10 = CSSRulePlugin.getRule(".ten:after");
    let lvcimagereveal11 = CSSRulePlugin.getRule(".eleven:after");
    let lvcimagereveal12 = CSSRulePlugin.getRule(".twelve:after");
    let lvcimagereveal13 = CSSRulePlugin.getRule(".thirteen:after");
    // let lvcimagereveal14 = CSSRulePlugin.getRule(".fourteen:after");
    // let lvcimagereveal15 = CSSRulePlugin.getRule(".fifthteen:after");
    // let lvcimagereveal16 = CSSRulePlugin.getRule(".sixteen:after");
    // let lvcimagereveal17 = CSSRulePlugin.getRule(".seventeen:after");
    // let lvcimagereveal18 = CSSRulePlugin.getRule(".eighteen:after");
    // let lvcimagereveal19 = CSSRulePlugin.getRule(".nineteen:after");
    // let lvcimagereveal20 = CSSRulePlugin.getRule(".twenty:after");
    // let lvcimagereveal21 = CSSRulePlugin.getRule(".twentyone:after");    


    let tl = new TimelineLite( {delay:.8});



    useEffect (() => {
       
    //content vars
      const headlineFirst = content.children[1];
      const headlineSecond =  headlineFirst.nextSibling;     
      const teamcontent = team.children[0];
      const brandedcontent = brandcontent.children[0];
      const herocontent = heroimage.children[0];
  

    // TweenMax.to(app, 0, {css: {visibility: 'visible'}}) 
    gsap.to('.layer-1', {y: '-100vh', delay: .5}); 
    gsap.to('.layer-2', {y: '-100vh', delay: .7});
    gsap.to('.layer-3', {y: '-100vh', delay: .9});
    gsap.to('.overlay', {y: '-00vh', delay: 1.5});
 


      gsap.from(" .toyotaheadline ", {
        scrollTrigger: {
            trigger : ".b31", 
            start : "top 90%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

    
      gsap.from(" .video2 ", {
        scrollTrigger: {
            trigger : ".video2",
            start : "top 90%",
            // scrub: true,
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        y: 400,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });


//footer animaiton 
//
//
      gsap.from(" .horizontalline, .verticalline", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });
      gsap.from(" .back, .next, .socialfeed ", {
        scrollTrigger: {
            trigger : ".footer",
            start : "top 100%",
            toggleActions: "restart none none reverse",
        },
        y: 20,
        ease: Power3.easeOut,
        opacity: 0,
        duration: 2,
      });

//IMages animation      

    gsap.to(lvcimagereveal, 1.4, { 
        scrollTrigger: {
            trigger : ".b36", 
            start : "top 60%",
            // markers : true,
            
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
         ease: Power2.easeInOut, 
    })
      
        gsap.to(lvcimagereveal1, 1.4, { 
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
             ease: Power2.easeInOut, 
        })

        gsap.from(lvcimage1, 1.4, {
            scrollTrigger: {
                trigger : ".first", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})     

            
            gsap.to(lvcimagereveal2, 1.4, { 
                scrollTrigger: {
                    trigger : ".second", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            
        gsap.from(lvcimage2, 1.4, {
            scrollTrigger: {
                trigger : ".second", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})    
            

            gsap.to(lvcimagereveal3, 1.4, { 
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(lvcimage3, 1.4, {
                scrollTrigger: {
                    trigger : ".third", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})          

            gsap.to(lvcimagereveal4, 1.4, { 
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                 ease: Power2.easeInOut, 
            })            

            gsap.from(lvcimage4, 1.4, {
                scrollTrigger: {
                    trigger : ".fourth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
                
            gsap.to(lvcimagereveal5, 1.4, { 
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            

            gsap.from(lvcimage5, 1.4, {
                scrollTrigger: {
                    trigger : ".fifth", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   

                
                gsap.to(lvcimagereveal6, 1.4, { 
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",                  
                        toggleActions: "restart none none reverse",
                    },
                    
                    width: "0%", 
                     ease: Power2.easeInOut, 
                })            
    
                gsap.from(lvcimage6, 1.4, {
                    scrollTrigger: {
                        trigger : ".sixth", 
                        start : "top 60%",
                        // markers : true,
                        toggleActions: "restart none none reverse",
                    },
                    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   
    
                    gsap.to(lvcimagereveal7, 1.4, { 
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            toggleActions: "restart none none reverse",
                        },
                        
                        width: "0%", 
                         ease: Power2.easeInOut, 
                    })            
        
                    gsap.from(lvcimage7, 1.4, {
                        scrollTrigger: {
                            trigger : ".seventh", 
                            start : "top 60%",
                            // markers : true,
                            toggleActions: "restart none none reverse",
                        },
                        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})   


gsap.to(lvcimagereveal8, 1.4, { 
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        toggleActions: "restart none none reverse",
    },
    
    width: "0%", 
        ease: Power2.easeInOut, 
})            

gsap.from(lvcimage8, 1.4, {
    scrollTrigger: {
        trigger : ".eight", 
        start : "top 60%",
        // markers : true,
        toggleActions: "restart none none reverse",
    },
    scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


    gsap.to(lvcimagereveal9, 1.4, { 
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            toggleActions: "restart none none reverse",
        },
        
        width: "0%", 
            ease: Power2.easeInOut, 
    })            
    
    gsap.from(lvcimage9, 1.4, {
        scrollTrigger: {
            trigger : ".nine", 
            start : "top 60%",
            // markers : true,
            toggleActions: "restart none none reverse",
        },
        scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
        
        gsap.to(lvcimagereveal10, 1.4, { 
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 60%",
                toggleActions: "restart none none reverse",
            },
            
            width: "0%", 
                ease: Power2.easeInOut, 
        })            
        
        gsap.from(lvcimage10, 1.4, {
            scrollTrigger: {
                trigger : ".ten", 
                start : "top 60%",
                // markers : true,
                toggleActions: "restart none none reverse",
            },
            scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  


            gsap.to(lvcimagereveal11, 1.4, { 
                scrollTrigger: {
                    trigger : ".eleven", 
                    start : "top 60%",
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            
                
            gsap.from(lvcimage11, 1.4, {
                scrollTrigger: {
                    trigger : ".eleven", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
            
            
            gsap.to(lvcimagereveal12, 1.4, { 
                scrollTrigger: {
                    trigger : ".twelve", 
                    start : "top 60%",
                    toggleActions: "restart none none reverse",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            
                
            gsap.from(lvcimage12, 1.4, {
                scrollTrigger: {
                    trigger : ".twelve", 
                    start : "top 60%",
                    // markers : true,
                    toggleActions: "restart none none reverse",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})  
            
            gsap.to(lvcimagereveal13, 1.4, { 
                scrollTrigger: {
                    trigger : ".thirteen", 
                    start : "top 80%",
                    toggleActions: "restart none none none",
                },
                
                width: "0%", 
                    ease: Power2.easeInOut, 
            })            
                
            gsap.from(lvcimage13, 1.4, {
                scrollTrigger: {
                    trigger : ".thirteen", 
                    start : "top 80%",
                    // markers : true,
                    toggleActions: "restart none none none",
                },
                scale: 1.6, ease: Power2.easeInOut, delay: -1.6})      
            





            
    //content animation intro
    tl.staggerFrom([headlineFirst.children, headlineSecond.children], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
 
    }, .15, 'begin')
    tl.staggerFrom([teamcontent.children, herocontent, brandedcontent, brandedcontent.nextSibling, brandedcontent.nextSibling.nextSibling], 1, {
        y:244,
        ease: Power3.easeOut,
        delay: 0.8,
        opacity:0,
    }, .10, 'begin')

    // image animation
//   window.scrollTo(0, 0)
    }, [tl]);

    


    return (

        
       
        <div className='container2' ><section id='topOfPage'></section>
        <div className='wrapperbrandbody'> 
        <div className="b1"></div>
        <div className="b2"></div>
        <div className="b3"></div>
        <div className="b4"></div>
        <div className="b5"></div>
        
        <div className="b6" ref={el => content = el} >
            <a className="t"></a>
            <div className="outer"> 
            <div className="headlinecategory">EXPERIENCE DESIGN</div>
            </div>
            <div className="outer">
            <div className="headlinebrand">Levis Vintage</div>
            </div>
        </div>
        <div className="b7"></div>
        <div className="b8"></div>
              
        <div className="b9"></div>
        <div className="b10"></div> 
        <div className="b11"></div>
        <div className="b12"></div>
        <div className="b13"></div>
        <div className="b14"></div>
        <div className="b15"></div> 
        <div className="b16" ref={el => team = el}>
             <div className="team">
                     <div className="title">AGENCY</div>
                    <div className="name">AKQA</div>                 
                    <div className="title">Group Creative Director</div>
                    <div className="name">Dwayne Koh</div>
                    <div className="title">Senior Creative</div>
                    <div className="name">Dave Knoph</div>
                    <div className="title">Art Director</div>
                    <div className="name">Ben Lebovitz</div>


            </div>
        </div>
         <div className="b17"></div> 
            <div className="b18" ref={el => brandcontent = el}>
                <div className="headlinebrandtext">This was one of the rare times where the Levis vault was opened to us, for our research around the history of Levis products. It was great to understand the birth of the brand and the little nuggets; like how during world war II, rivets were removed from the jeans, so that metal could be saved to make ammunition.  </div>
                <p><div className="headlinebrandtext">This timeline based website is stock-loaded with stunning animations that highlight Levi’s most iconic products of all time, featured as pieces of art hanging on the walls of a museum. Each time period showcases how Levi's shaped culture like no other brand can claim. – Marlon Brando wearing the 1947 501® in the motorcycle movie “The Wild Ones" or Elvis Presley wearing the 1953 Type II trucker on stage.</div></p>
                <p><div className="headlinebrandtext">Many of the products are recreated from original archives dating back to the 1880s, found inside old mines, or in the walls of cabins. We photographed all the products and archives the same as the shopper might view them inside a case at a museum. </div></p>                
            </div>   
         <div className="b19"></div>
         <div className="b20"></div>   
         <div className="b21"></div>
         <div className="b22"></div> 
         <div className="b23"></div>
         <div className="b24"></div>   
         <div className="b25"></div>   
         
         <div className="b26 imagehero" ref={el => heroimage = el}><img width="100%" src={hero}  /></div>   
         <div className="b27"></div>
         <div className="b28"></div> 
         <div className="b29"></div>
         <div className="b30"></div>

         <div className="b31"><div className="toyotaheadline">Levis LVC</div></div>
         <div className="b32"></div>   
         <div className="b33"></div>
         <div className="b34"></div>   
         <div className="b35"></div>

         <div className="b36"></div>  
         <div className="b37"></div> 
         <div className="b38"></div>
         <div className="b39"></div>
         <div className="b40"></div>  

         <div className="b41">
         <div className="video640">
        <ReactPlayer className='react-player'
        url="https://vimeo.com/229615881"
        height='100%'
        width='100%'
        /> </div>

         </div>
         <div className="b42"></div>   
         <div className="b43">    

        </div> 
         <div className="b44"></div>   
         <div className="b45"></div> 

         <div className="b46 imagestoyota">
             <div className="first"><img ref={el => lvcimage1 = el} width="100%" src={Img1}  /></div>
             <div className="second"><img ref={el => lvcimage2 = el} width="100%" src={Img2}  /></div>
             <div className="third"><img ref={el => lvcimage3 = el} width="100%" src={Img3}  /></div>

             <div className="fourth"><img ref={el => lvcimage4 = el} width="100%" src={Img4}  /></div>
             <div className="fifth"><img ref={el => lvcimage5 = el} width="100%" src={Img5}  /></div>
             <div className="sixth"><img ref={el => lvcimage6 = el} width="100%" src={Img6}  /></div>
             <div className="seventh"><img ref={el => lvcimage7 = el} width="100%" src={Img7} /></div>
             <div className="eight"><img ref={el => lvcimage8 = el} width="100%" src={Img8}  /></div>
             <div className="nine"><img ref={el => lvcimage9 = el} width="100%" src={Img9}  /></div>
             <div className="ten"><img ref={el => lvcimage10 = el} width="100%" src={Img10}  /></div>
             <div className="eleven"><img ref={el => lvcimage11= el} width="100%" src={Img11}  /></div>
             <div className="twelve"><img ref={el => lvcimage12 = el} width="100%" src={Img12}  /></div>
             <div className="thirteen"><img ref={el => lvcimage13 = el} width="100%" src={Img13}  /></div>             
             {/* <div className="ten"><img ref={el => lvcimage10 = el} width="100%" src={Img11}  /></div>
             <div className="eleven"><img ref={el => lvcimage11= el} width="100%" src={Img12}  /></div>
             <div className="twelve"><img ref={el => lvcimage12 = el} width="100%" src={Img13}  /></div>
             {/* <div className=" eleven"><img width="50%" src={Img13} /><img width="50%" src={Img14}  /></div> */}
             {/* <div className="thirteen"><img ref={el => lvcimage13 = el} width="100%" src={Img14}  /></div>
             <div className="fourteen"><img ref={el => lvcimage14 = el} width="100%" src={Img15}  /></div>
             <div className="fifthteen"><img ref={el => lvcimage15 = el} width="100%" src={Img16}  /></div>
             <div className="sixteen"><img ref={el => lvcimage16 = el} width="100%" src={Img17}  /></div>
             <div className="seventeen"><img ref={el => lvcimage17 = el} width="100%" src={Img18}  /></div>
             <div className="eighteen"><img ref={el => lvcimage18 = el} width="100%" src={Img19}  /></div>
             <div className="nineteen"><img ref={el => lvcimage19 = el} width="100%" src={Img20}  /></div>
             <div className="twenty"><img ref={el => lvcimage20 = el} width="100%" src={Img21}  /></div>  */}
   
        </div>             
         <div className="b47"></div> 
         <div className="b48"></div> 
         <div className="b49"></div>   
         <div className="b50"></div>

         {/* <div className="b51"></div>
         <div className="b52"></div>
         <div className="b53"></div>
         <div className="b54"></div>   
         <div className="b55"></div>
         <div className="b56"></div> 
         <div className="b57"></div>   
         <div className="b58"></div>
         <div className="b59"></div>
         <div className="b60"></div>


         <div className="b61"></div>
         <div className="b62"></div>   
         <div className="b63"></div>
         <div className="b64"></div>   
         <div className="b65"></div> */}
{/* 
         <div className="jordanb66"></div>
         <div className="jordanb67"></div>
         <div className="jordanb68"></div>
         <div className="jordanb69"></div>   
         <div className="jordanb70"></div> */}

         {/* <div className="b72"></div>  
         <div className="b73"></div>  
         <div className="b74"></div>   
         <div className="b75"></div>  */}

         {/* <div className="b76">76</div>
         <div  className="b77">77</div>   
         <div  className="b78">78</div>
         <div  className="b79">79</div>   
         <div  className="b80">80</div> */}
       </div>  
       {/* close wrapper */}
        <div className="footer"> 
                    <div className="horizontalline"></div>
                    <div className="nested3">
                        <div className="back"><Link to='/leviscom'>Back<p>Levis.com</p></Link></div>
                        <div className="verticalline"><div></div></div>
                        <div className="next"><Link to='/asics'>Next<p>Asics</p></Link></div>
                    </div>
                    <div className="horizontalline"></div>

                    <div className="socialfeed">
                        <div className="item1">
                        <div><a className="cool-link" href="https://www.linkedin.com/in/kohdwayne/" target="_blank" rel="noopener noreferrer">Linkedin</a></div>
                            <div><a className="cool-link" href="https://www.instagram.com/dwaynekoh/" target="_blank" rel="noopener noreferrer">Instagram</a></div>
                            <div><a className="cool-link" href="https://twitter.com/acidlice" target="_blank" rel="noopener noreferrer">Twitter</a></div>
                        </div>
                        <div className="item2"><AnchorLink className="cool-link" href='#topOfPage'>Top</AnchorLink></div>
                        {/* <div className="item2"><a className="cool-link" href="#top">Top</a> </div> */}
                     
                    </div>
        </div>

       
<div className="overlay">
    <div className="layer layer-1"></div>
    <div className="layer layer-2"></div>
    <div className="layer layer-3"></div>
</div>




    </div>

 );
}

export default Lvc;